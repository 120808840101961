import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FunnelService } from './funnel.service';
import mixpanel from 'mixpanel-browser';
import { Wilson } from 'src/def/Wilson';
import Resource = Wilson.Resource;
import Sharelink = Wilson.Sharelink;

@Injectable({
  providedIn: 'root',
})
export class SharelinkService {
  private controller = 'sharelink';
  private multiShareListSubject = new BehaviorSubject<Resource[]>([]);
  private multiShareActiveSubject = new BehaviorSubject<boolean>(false);

  multiShareList$ = this.multiShareListSubject.asObservable();
  multiShareActive$ = this.multiShareActiveSubject.asObservable();

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private apiService: ApiService,
    private funnelService: FunnelService
  ) {}

  getLevel(): string {
    return this.funnelService.getCurrentFunnel().parentProgramId.slice(-1);
  }

  toggleMultishareActive(active: boolean): void {
    this.multiShareActiveSubject.next(active);
  }

  checkIfSharingIsAllowed(): boolean {
    return this.funnelService.getCurrentFunnel()?.isSharingAllowed;
  }

  setMultiShareList(resource: Resource): void {
    const currentValue = this.multiShareListSubject.value;
    const exists = currentValue.some((res: Resource) => res.id === resource.id);
    if (exists) {
      // it already exists, remove it
      this.multiShareListSubject.next(
        currentValue.filter((res: Resource) => res.id !== resource.id)
      );
    } else {
      // doesn't exist, add it
      this.multiShareListSubject.next([...currentValue, resource]);
    }
  }

  removeMultiShareResource(resource: Resource) {
    const shareList = this.multiShareListSubject.value.filter(
      (r) => r.id !== resource.id
    );
    this.multiShareListSubject.next(shareList);
  }

  clearMultiShareList(): void {
    this.multiShareListSubject.next([]);
  }

  generate(resourceIds: string[]): Observable<Sharelink> {
    const programId = this.funnelService.getCurrentFunnel().id;
    return this.apiService.post<Sharelink>(
      `${this.controller}/Generate/${programId}`,
      resourceIds
    );
  }

  retrieve(id: string): Observable<Resource[]> {
    return this.apiService.get<Resource[]>(`${this.controller}/Retrieve/${id}`);
  }

  shareLinkToClipboard(resourceIds: string[]): Observable<Sharelink> {
    const level = this.getLevel();
    return this.generate(resourceIds).pipe(
      tap((sharelink: Sharelink) => {
        this.logSharelinkGeneration(sharelink, 'Copy to Clipboard');
        navigator.clipboard.writeText(
          `${this.baseUrl}p/sharelink/${sharelink.id}?hub=${level}`
        );
      })
    );
  }

  shareClassroom(resourceIds: string[]): Observable<Sharelink> {
    const level = this.getLevel();
    return this.generate(resourceIds).pipe(
      tap((sharelink: Sharelink) => {
        this.logSharelinkGeneration(sharelink, 'Open in Google Classroom');
        const windowReference = window.open('', '_blank');
        windowReference.location.assign(
          `https://classroom.google.com/share?url=${this.baseUrl}p/sharelink/${sharelink.id}?hub=${level}`
        );
      })
    );
  }

  shareEmail(resourceIds: string[]): Observable<Sharelink> {
    return this.generate(resourceIds).pipe(
      tap((sharelink: Sharelink) => {
        this.logSharelinkGeneration(sharelink, 'Share via Email');
        window.location.href = this.generateShareEmailHref(sharelink);
      })
    );
  }

  generateShareEmailHref(sharelink: Sharelink): string {
    const level = this.getLevel();
    return `mailto:?subject=Communities Resource&body=Your Shared Link ${this.baseUrl}p/sharelink/${sharelink.id}?hub=${level}`;
  }

  private logSharelinkGeneration(shareLink: Sharelink, type: string) {
    mixpanel.track('Sharelink Generated', {
      'Sharelink Type': type,
      'Sharelink Id': shareLink.id,
    });
  }
}
