import { Pipe, PipeTransform } from '@angular/core';
import { CamelToSentencePipe } from '@wilson/wilsonng';
import { Wilson } from 'src/def/Wilson';
import ResourceType = Wilson.ResourceType;
@Pipe({
  name: 'userFriendlyResourceType',
})
export class ResourceTypePipe implements PipeTransform {
  lookup = {
    admin: {
      [ResourceType.text]: 'generic (textPassage)',
    },
    customer: {
      [ResourceType.text]: 'textPassage',
    },
  };

  constructor(private ctsPipe: CamelToSentencePipe) {}

  private capitalize(value: string): string {
    let output = '';
    for (let i = 0; i < value.length; i++) {
      if (i === 0 || !/[A-Za-z]/.test(value[i - 1])) {
        output += value[i].toUpperCase();
      } else {
        output += value[i];
      }
    }

    return output;
  }

  transform(value: string, isCustomer = false): string {
    const mappedValue = isCustomer
      ? this.lookup.customer[value] || this.lookup.admin[value]
      : this.lookup.admin[value];
    return this.capitalize(this.ctsPipe.transform(mappedValue || value));
  }
}
