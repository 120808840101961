import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { PinStatusChangeEvent } from 'src/app/services/pin.service';
import { Wilson } from 'src/def/Wilson';
import { TileClickEvent } from '../tile/tile.component';
import Resource = Wilson.Resource;
import ResourceType = Wilson.ResourceType;
import {
  animate,
  state,
  style,
  transition,
  trigger,
  AnimationEvent,
} from '@angular/animations';

/**
 * The *TileGridComponent* has a few states that determine the content
 * displayed, and timing of the transitions between these states.
 *
 * On initial load, the state is set to "skeleton," which gives a
 * 0.75 opacity to the tiles, and sets the underlying tile source
 * to the pre-determined collection of skeleton tiles (from the
 * page size on initialization.)
 *
 * When data is given to the grid, and *ngOnChanges* is triggered, no
 * animation will occur if this is the first change of the dataset,
 * so that the skeletons hide quickly to display the content.
 * **[skeleton -> show]**
 *
 * Otherwise, if the data is changed or the *isLoading* input is true,
 * the current tiles will change to a "hide" state and will be
 * replaced by skeleton tiles.
 * **[show -> hide -> skeleton]**
 */
@Component({
  selector: 'app-tile-grid',
  templateUrl: './tile-grid.component.html',
  styleUrls: ['./tile-grid.component.scss'],
  animations: [
    trigger('gridState', [
      state(
        'hide',
        style({
          opacity: 0,
        })
      ),
      state(
        'show',
        style({
          opacity: 1,
        })
      ),
      state(
        'skeleton',
        style({
          opacity: 0.75,
        })
      ),
      transition('hide => skeleton', [animate('0.5s')]),
      transition('show <=> hide', [animate('0.2s')]),
    ]),
  ],
})
export class TileGridComponent implements OnInit, OnChanges {
  @Input() data: Resource[];
  @Input() linkText: string;
  @Input() page = 1;
  @Input() pageSize = 9;
  @Input() totalItems = 9;
  @Input() isLoading = false;
  @Input() draggable = false;
  @Input() showPin = true;
  @Input() columnClass = 'col-12 col-md-6 col-lg-4';
  @Input() tileClass = '';
  @Input() routePrefix = '';
  @Input() isMultiShareModeActive = false;
  @Input() isStandaloneResource = false;
  @Input() multiShareIds: string[] = [];
  @ContentChild(TemplateRef) template: TemplateRef<HTMLElement>;
  @Output() pageChanged = new EventEmitter<PageEvent>();
  @Output() tileClicked = new EventEmitter<TileClickEvent>();
  @Output() pinStatusChanged = new EventEmitter<PinStatusChangeEvent>();

  previousMultiShareIds: string[] = [];

  state: string = 'skeleton';
  skeletonTiles: Resource[];
  tileSource: Resource[];

  types = ResourceType;

  ngOnInit(): void {
    this.skeletonTiles = [...Array(this.pageSize).keys()].map((i) => {
      return { name: null, id: `${i}` } as Resource;
    });

    this.tileSource = this.skeletonTiles;
  }

  canChange(event: AnimationEvent): void {
    if (event.toState === 'hide') {
      this.setTileSource();
      this.state = this.isLoading ? 'skeleton' : 'show';
    }

    if (event.toState === 'show') {
      this.setTileSource();
    }
  }

  setTileSource(): void {
    this.data = this.data || [];

    this.tileSource =
      this.isLoading || !this.data.length
        ? this.skeletonTiles.slice(0, this.pageSize)
        : this.data.slice(0, this.pageSize);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.['data']?.firstChange || this.isLoading) this.state = 'hide';
    else if (this.data) this.state = 'show';

    const newShareListLength = this.multiShareIds.length;
    const oldShareListLength = this.previousMultiShareIds.length;
    // only if the change was related to the multishare selections
    if (newShareListLength !== oldShareListLength) {
      this.previousMultiShareIds = this.multiShareIds;
    }
  }

  onPageChange(event: PageEvent): void {
    this.pageChanged.emit({ ...event, page: event.page + 1 });
  }

  handleTileClick(event: TileClickEvent): void {
    this.tileClicked.emit(event);
  }

  emitPinStatusChange(event: PinStatusChangeEvent): void {
    this.pinStatusChanged.emit(event);
  }

  getTrackingId(index: number, item: Resource): string {
    return item.id;
  }
}

export interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
