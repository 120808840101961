import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { tap } from 'rxjs/operators';
import { Wilson } from 'src/def/Wilson';
import { Observable } from 'rxjs';
import HubProgramUnits = Wilson.HubProgramUnits;
import HubUnitWeeks = Wilson.HubUnitWeeks;

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  unitsFilter: HubProgramUnits[];

  constructor(private apiService: ApiService) {}

  getUnitsFilter(): Observable<HubProgramUnits[]> {
    return this.apiService
      .get<HubProgramUnits[]>('resource/GetHubUnitWeeks')
      .pipe(
        tap((units) => {
          this.unitsFilter = units;
        })
      );
  }

  getUnitsByFunnel(funnel: string): HubUnitWeeks[] {
    const unitsByFunnel = this.unitsFilter.find((x) => x.programKey === funnel);
    if (!unitsByFunnel) {
      console.error('No units were found in this program');
      return;
    }
    return unitsByFunnel.units;
  }
}
