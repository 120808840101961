<wilson-tile
  [imageSrc]="data.imageUrl"
  imageAlt=""
  [flagText]="flagText"
  [flagClass]="flagClass"
  [styleClass]="styleClass + ' wrs-card-activity-widget'"
  (tileClicked)="tileClick()"
  [clickable]="true"
>
  <h4 class="tile-heading">{{ data.name }}</h4>
  <div class="tile-description">
    <div class="w-100 my-auto" (click)="$event.stopPropagation()">
      <wilson-dropdown
        #activitySteps
        [showListOnFocus]="true"
        [options]="steps"
        [(ngModel)]="activeStep"
        (ngModelChange)="goToSelectedStep(activitySteps)"
        optionLabel="title"
        optionDisabled="disabled"
        placeholder="Select a step"
      ></wilson-dropdown>
    </div>
  </div>
  <a
    class="tile-link"
    title="WRS Card Activity"
    [href]="activityUrl"
    target="_blank"
  >
    View WRS Card Activity »
  </a>

  <ng-content></ng-content>

  <div tile-header>
    <ng-content select="[tile-header]"></ng-content>
  </div>
</wilson-tile>
