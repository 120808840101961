import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FiltersService } from 'src/app/services/filters.service';
import { FunnelService } from 'src/app/services/funnel.service';
import { Wilson } from 'src/def/Wilson';
import HubUnitWeeks = Wilson.HubUnitWeeks;
import WidgetFunHubChooser = Wilson.WidgetFunHubChooser;

@Component({
  selector: 'app-choose-unit',
  templateUrl: './choose-unit.component.html',
})
export class ChooseUnitComponent implements OnInit {
  @Input() data: WidgetFunHubChooser;
  @Input() flagText = '';
  @Input() flagClass = '';
  @Input() styleClass = '';

  units: HubUnitWeeks[];
  funnel: string;
  placeholder = 'Select a Unit';
  options: string[] = [];
  selectedUnit: HubUnitWeeks;

  constructor(
    private router: Router,
    private filterService: FiltersService,
    private funnelService: FunnelService
  ) {}

  ngOnInit(): void {
    this.funnel = this.funnelService.getCurrentFunnel().id;
    if (!this.funnel.includes('hub')) {
      console.error('A hub program is required to init this widget');
      return;
    }
    this.getUnits();
  }

  getUnits(): void {
    this.units = this.filterService.getUnitsByFunnel(this.funnel);
  }
  navigate(): void {
    this.router.navigate([
      this.funnel,
      'cc',
      this.funnel + '-' + this.selectedUnit.key + '.1',
    ]);
  }
}
