import { Component, Input, OnInit } from '@angular/core';
import { FunnelService } from 'src/app/services/funnel.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TeacherSupportService } from 'src/app/services/teacher-support.service';
import mixpanel from 'mixpanel-browser';
import { Wilson } from 'src/def/Wilson';
import ProgramResource = Wilson.ProgramResource;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() isAdmin = false;

  showPinnedResourcesLink = true;
  discussionUrl: string;
  funnelId: string;
  gettingStartedVisible = false;

  constructor(
    private funnelService: FunnelService,
    private teacherSupportService: TeacherSupportService
  ) {}

  ngOnInit(): void {
    this.getDiscussionData().subscribe();
  }

  getDiscussionData(): Observable<ProgramResource> {
    return this.funnelService.watchCurrentFunnel().pipe(
      tap((funnel) => {
        this.funnelId = funnel?.id;
        this.discussionUrl = funnel?.discussionBoardUrl;
        this.gettingStartedVisible = funnel?.gettingStartedVisible;
      })
    );
  }

  showGettingStartedDialog(): void {
    this.teacherSupportService.updateDisplayGettingStartedDialog(true);
    mixpanel.track('Go to Getting Started Video');
  }

  /* istanbul ignore next */
    track(message: string): void{
    mixpanel.track(message);
  }
}
