import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { debounce } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FunnelService } from 'src/app/services/funnel.service';
import { SearchResults, SearchService } from 'src/app/services/search.service';
import { Wilson } from 'src/def/Wilson';
import ExternalLinkResource = Wilson.ExternalLinkResource;
import ProgramResource = Wilson.ProgramResource;
import ProgramType = Wilson.ProgramType;
import Resource = Wilson.Resource;
import ResourceType = Wilson.ResourceType;

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss'],
})
export class TitleBarComponent implements OnInit {
  @Input() heading: string;
  @Input() altHeading: string;
  @Input() isLoading = false;
  @Input() searchTerm = '';
  @Input() searchBarStyle: SearchBarStyle = SearchBarStyle.mini;
  @Input() placeholder: string;
  @Input() autoSuggestions = false;
  @Input() autoResults = false;
  @Input() childTemplateVisible = true;
  @Output() searchTermChanged = new EventEmitter<string>();
  @Output() searchButtonClicked = new EventEmitter<SearchEvent>();
  @ContentChild(TemplateRef) template: TemplateRef<HTMLElement>;

  searchStyle = SearchBarStyle;
  searchSuggestions: string[];
  searchResults: Resource[];
  debouncedOnSearchTermChanged = debounce(() => {
    this.searchTermChanged.emit(this.searchTerm);
    this.fillSuggestions();
    this.fillResults().subscribe();
  }, 1000);

  constructor(
    private router: Router,
    private funnelService: FunnelService,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.fillPlaceholder().subscribe();
  }

  onSearchTermChanged(): void {
    this.debouncedOnSearchTermChanged();
  }

  fillPlaceholder(): Observable<ProgramResource> {
    return this.funnelService.watchCurrentFunnel().pipe(
      tap(() => {
        const funnel = this.funnelService.getCurrentFunnel();
        this.placeholder = `Search ${
          funnel.subType === ProgramType.hub ? '' : 'the '
        }${funnel.name}`;
      })
    );
  }

  fillSuggestions(): void {
    if (this.autoSuggestions && this.searchTerm?.trim().length > 1) {
      this.searchService.suggestion(this.searchTerm, 8).then((suggestions) => {
        this.searchSuggestions = suggestions;
      });
    } else {
      this.searchSuggestions = [];
    }
  }
  fillResults(): Observable<SearchResults> {
    if (this.autoResults && this.searchTerm?.trim().length > 1) {
      mixpanel.track('Search Performed', { 'Search Term': this.searchTerm });
      return this.searchService
        .search(
          this.searchTerm,
          [
            ResourceType.expertTip,
            ResourceType.externalLink,
            ResourceType.pdf,
            ResourceType.soundCards,
            ResourceType.cursiveCards,
            ResourceType.video,
            ResourceType.file,
            ResourceType.text,
            ResourceType.iframe,
          ],
          null,
          null,
          0,
          3
        )
        .pipe(
          tap((results) => {
            // fill search results, omitting resource ids in the current url
            const url = this.router.url;
            this.searchResults = results.items.filter(
              (item) => url.indexOf(item.id) === -1
            );
          })
        );
    } else {
      this.searchResults = [];
      return of(null);
    }
  }

  suggestionClickSearch(suggestion: string): void {
    this.searchTerm = suggestion.replace(/<[^>]*>/g, '');
    this.searchAndRedirect();
  }

  private getActiveElement(): HTMLButtonElement | HTMLInputElement {
    return document.activeElement as HTMLButtonElement;
  }

  private getElementToFocus(
    inputElement: HTMLInputElement,
    listContainer: HTMLDivElement,
    isDown: boolean
  ): HTMLButtonElement | HTMLInputElement {
    let element: HTMLButtonElement | HTMLInputElement;
    const firstButton: HTMLButtonElement =
      listContainer.querySelector('button');
    const lastButton: HTMLButtonElement = listContainer.querySelector(
      'ul:last-child li:last-of-type button'
    );
    const activeElement = this.getActiveElement();

    if (activeElement === inputElement) {
      // if currently focused on the input
      // move from input to either first or last button
      element = isDown ? firstButton : lastButton;
    } else {
      // if not currently focused on input
      // if we're at first or last button
      // move to input element
      const upFromFirstButton = activeElement === firstButton && !isDown;
      const downFromLastButton = activeElement === lastButton && isDown;
      if (upFromFirstButton || downFromLastButton) {
        element = inputElement;
      } else {
        // otherwise, move to prev/next button
        const buttons = Array.from(listContainer.querySelectorAll('button'));
        const currentIndex = buttons.findIndex(
          (button) => button === activeElement
        );
        element = isDown
          ? buttons[currentIndex + 1]
          : buttons[currentIndex - 1];
      }
    }

    return element;
  }

  cycleFocus(
    event: KeyboardEvent,
    listContainer: HTMLDivElement,
    inputElement: HTMLInputElement
  ): void {
    const keysToIgnore = ['Enter', 'Tab'];
    if (keysToIgnore.includes(event.key)) {
      return;
    }

    const canNavigate = !listContainer.hasAttribute('hidden');
    const isTyping = !['ArrowUp', 'ArrowDown'].includes(event.key);

    if (canNavigate) {
      if (!isTyping) {
        event.preventDefault();
        const element = this.getElementToFocus(
          inputElement,
          listContainer,
          event.key === 'ArrowDown'
        );
        element.focus();
      } else {
        inputElement.focus();
      }
    }
  }

  handleSearch(e: Event): void {
    e.preventDefault();
    this.searchAndRedirect();
  }

  navigateToResource(resource: Resource): void {
    if (resource.type === ResourceType.externalLink) {
      this.openExternallLink((resource as ExternalLinkResource).url);
    } else {
      this.routeToResource(resource.id);
    }
    this.resetSearch();
  }

  routeToResource(resourceId: string): void {
    const funnelId = this.funnelService.getCurrentFunnel().id;
    this.router.navigate([funnelId, 'viewer', resourceId]);
  }

  openExternallLink(resourceUrl: string): void {
    window.open(resourceUrl, '_blank');
  }

  searchAndRedirect(): void {
    // Don't perform a search if the search query is invalid
    if (!this.searchTerm || this.searchTerm.trim().length < 2) {
      return;
    }

    let eventCancelled = false;
    this.searchButtonClicked.emit({
      searchTerm: this.searchTerm,
      cancel: () => {
        eventCancelled = true;
      },
    });

    if (eventCancelled) {
      return;
    }

    const funnelId = this.funnelService.getCurrentFunnel().id;
    const query = this.searchTerm;
    this.router.navigate([funnelId, 'search'], {
      queryParams: { query },
    });
  }

  resetSearch(): void {
    this.searchTerm = '';
    this.searchResults = null;
    this.searchSuggestions = null;
  }
}

export interface SearchEvent {
  searchTerm: string;
  cancel: () => void;
}

export enum SearchBarStyle {
  hidden = 'hidden',
  mini = 'mini',
  full = 'full',
}
