import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-activities-canvas',
  templateUrl: './activities-canvas.component.html',
  styleUrls: ['./activities-canvas.component.scss'],
})
export class ActivitiesCanvasComponent implements OnChanges {
  @Input() drawingEnabled = false;
  @Input() canvasSize: { height: 0; width: 0 };
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  showCanvas = false;
  isDrawing = false;
  ctx: CanvasRenderingContext2D;
  pos = { x: 0, y: 0 };

  ngOnChanges(): void {
    if (this.drawingEnabled) {
      this.showCanvas = true;
      this.initCanvas();
    } else {
      this.showCanvas = false;
    }
  }

  initCanvas(): void {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.setCanvasSize();
    this.resetCanvas();
  }

  // Set the canvas to the same size as the #activityZone
  setCanvasSize(): void {
    this.ctx.canvas.width = this.canvasSize.width;
    this.ctx.canvas.height = this.canvasSize.height;
  }

  // Clear the canvas & reset initial position for good measure
  resetCanvas(): void {
    this.ctx.clearRect(0, 0, this.canvasSize.width, this.canvasSize.height);
    this.pos = { x: 0, y: 0 };
  }

  mouseStart(event: MouseEvent): void {
    this.isDrawing = true;

    this.setPosition(event.clientX, event.clientY);
    // pen settings
    this.ctx.lineWidth = 5;
    this.ctx.lineCap = 'round';
    this.ctx.strokeStyle = 'black';

    this.draw(event);
  }

  draw(event: MouseEvent): void {
    if (this.isDrawing) {
      this.ctx.beginPath();
      this.ctx.moveTo(this.pos.x, this.pos.y); // from position
      this.setPosition(event.clientX, event.clientY);
      this.ctx.lineTo(this.pos.x, this.pos.y); // to position
      this.ctx.stroke();
    }
  }

  touchStart(event: TouchEvent): void {
    event.preventDefault();
    // grab touch points
    const touch = event.touches.item(0);
    const [clientX, clientY] = [touch.clientX, touch.clientY];
    // generate mouse event
    const mouseEvent = new MouseEvent('mousestart');
    this.mouseStart({ ...mouseEvent, clientX, clientY });
  }

  touchDraw(event: TouchEvent): void {
    event.preventDefault();
    // grab touch points
    const touch = event.touches.item(0);
    const [clientX, clientY] = [touch.clientX, touch.clientY];
    // generate mouse event
    const mouseEvent = new MouseEvent('mousemove');
    this.draw({ ...mouseEvent, clientX, clientY });
  }

  setPosition(x: number, y: number) {
    const canvasOffset = this.canvas.nativeElement.getBoundingClientRect();
    this.pos.x = x - canvasOffset.left;
    this.pos.y = y - canvasOffset.top;
  }

  endEvent(): void {
    this.isDrawing = false;
  }
}
