import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import {
  ApiService,
  AuthenticationService,
  User as AuthenticationServiceUser,
} from '@wilson/wilsonng';
import { Observable, of } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import User = Wilson.User;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private controller = 'user';
  user: User;
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService
  ) {
    /* istanbul ignore if */
    if (window['Cypress']) window['UserService'] = this;
  }

  getUser(): Observable<User> {
    return this.apiService
      .get<User>(`${this.controller}/GetUserInformation`)
      .pipe(
        tap((user) => {
          this.user = user;
        })
      );
  }

  loginAndGetUser(): Observable<User> {
    return this.apiService
      .post<User>(`${this.controller}/LoginAndGetUser`)
      .pipe(
        tap((user) => {
          this.user = user;
        })
      );
  }

  updateCurrentProgram(programId: string): Observable<void> {
    if (programId !== this.user.lastViewedProgramId) {
      this.user.lastViewedProgramId = programId;
      return this.apiService.patch(
        `${this.controller}/UpdateCurrentProgram/${programId}`
      );
    }
    return of(null);
  }

  updateLastViewedTutorialPopupDatetime(): Observable<void> {
    return this.apiService.patch(
      `${this.controller}/UpdateLastViewedTutorialPopupDatetime`
    );
  }

  updateLastViewedAnnouncementDatetime(): Observable<void> {
    return this.apiService.patch(
      `${this.controller}/UpdateLastViewedAnnouncementDatetime`
    );
  }

  updateLastViewedExpertTipDatetime(): Observable<void> {
    return this.apiService.patch(
      `${this.controller}/UpdateLastViewedExpertTipDatetime`
    );
  }

  updateLastAcceptedTermsConditionsDatetime(): Observable<Date> {
    return this.apiService.patch(
      `${this.controller}/UpdateLastAcceptedTermsConditionsDatetime`
    );
  }

  loginTester(): Observable<AuthenticationServiceUser> {
    return this.apiService
      .post(`${this.controller}/login-tester`)
      .pipe(switchMap(() => this.authenticationService.getUser()));
  }

  userIsLoggedIn(): boolean {
    return !!this.authenticationService.getUser();
  }
}
