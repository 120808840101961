import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  FormGroupService,
  RouterNavigationService,
  SpinnerService,
  UtilityService,
} from '@wilson/wilsonng';
import { ConfirmationService, MessageService } from 'primeng/api';

/**
 * pass-through service for various UX features like messaging, auto-navigation and action confirmation
 */
@Injectable({
  providedIn: 'root',
})
export class UxService {
  constructor(
    public routerNavigationService: RouterNavigationService,
    public messageService: MessageService,
    public formGroupService: FormGroupService,
    public confirmationService: ConfirmationService,
    public utilityService: UtilityService,
    public spinnerService: SpinnerService,
    public datePipe: DatePipe
  ) {}
}
