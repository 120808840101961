import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../../services/user.service';

@Pipe({
  name: 'externalLinkMassager',
})
export class ExternalLinkMassagerPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  transform(value: string): string {
    const link = value
      .replace('{user:email}', this.userService.user.email)
      .replace('{user:name}', this.userService.user.name)
      .replace('{user:id}', this.userService.user.wilsonId)
      .replace('{date}', new Date().toISOString())
      .replace('{url:current}', location.href)
      .replace('{cacheKill}', new Date().getTime().toString());
    return encodeURI(link);
  }
}
