import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CollectionType,
  ResourceService,
} from 'src/app/services/resource.service';
import { PinStatusChangeEvent } from 'src/app/services/pin.service';
import { FunnelService } from 'src/app/services/funnel.service';
import { Observable } from 'rxjs';
import { LinkTileClickEvent } from '@wilson/wilsonng';
import { HttpResponse } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import mixpanel from 'mixpanel-browser';
import { SharelinkService } from 'src/app/services/sharelink.service';
import { MessageService } from 'primeng/api';
import { Wilson } from 'src/def/Wilson';
import BaseResource = Wilson.BaseResource;
import ExternalLinkResource = Wilson.ExternalLinkResource;
import Resource = Wilson.Resource;
import WidgetResource = Wilson.WidgetResource;
import ResourceType = Wilson.ResourceType;
import { ResourceNotificationService } from 'src/app/services/resource-notification.service';
import { ExternalLinkMassagerPipe } from 'src/app/pipes/external-link-massager/external-link-massager.pipe';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
})
export class TileComponent implements OnInit, OnChanges {
  @Input() data: BaseResource;
  @Input() linkText = 'Open';
  @Input() routePrefix = '';
  @Input() styleClass = '';
  @Input() footerLink = false;
  @Input() showPin = true;
  @Input() isStripped = false;
  @Input() isAttachment = false;
  @Input() isMultiShareModeActive = false;
  @Input() isStandaloneResource = false;
  @Output() tileClicked = new EventEmitter<TileClickEvent>();
  @Output()
  pinStatusChanged = new EventEmitter<PinStatusChangeEvent>();

  // data values
  name: string | SafeHtml;
  description: string | SafeHtml;
  imageUrl: string;
  href: string;
  actualLinkText: string;
  extraClasses = '';
  flagText = '';
  flagClass = '';
  showFooterLink = false;
  isWidget = false;
  downloadable = false;
  userIsAdmin = false;

  resourceIds: string[] = [];

  icons: { [key: string]: string } = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private funnelService: FunnelService,
    private resourceService: ResourceService,
    private userService: UserService,
    private messageService: MessageService,
    private sharelinkService: SharelinkService,
    private resourceNotificationService: ResourceNotificationService,
    private externalLinkMassagerPipe: ExternalLinkMassagerPipe
  ) {}

  ngOnInit(): void {
    this.overrideLinkText();
    this.initIcons();
    this.userIsAdmin = this.userService.user.isAdmin;
  }

  ngOnChanges(): void {
    this.mapData();
  }

  handleTileClickOrMultiShare(
    resource: Resource,
    event: LinkTileClickEvent
  ): void {
    if (
      this.isMultiShareModeActive &&
      !(this.data as Resource).isSharingPermitted
    ) {
      return;
    } else if (
      this.isMultiShareModeActive &&
      (this.data as Resource).isSharingPermitted
    ) {
      this.sharelinkService.setMultiShareList(resource);
      //calling event.cancel here prevents external links from launching whilst multi share mode is active
      event.cancel();
    } else {
      this.handleClick(event);
    }
  }

  sharelinkToClipboard(id: string): void {
    this.sharelinkService.shareLinkToClipboard([id]).subscribe();
    this.messageService.add({
      detail:
        'Share link successfully copied! This link will be valid for 90 days.',
      closable: false,
    });
  }

  shareEmail(id: string): void {
    this.sharelinkService.shareEmail([id]).subscribe();
  }

  shareClassRoom(id: string): void {
    this.sharelinkService.shareClassroom([id]).subscribe();
  }

  initIcons(): void {
    const sets = [
      { types: [ResourceType.video], icon: 'fas fa-video' },
      { types: [ResourceType.pdf], icon: 'fas fa-file-pdf' },
      {
        types: [ResourceType.externalLink],
        icon: 'fas fa-external-link-square',
      },
      {
        types: [ResourceType.collection, ResourceType.program],
        icon: 'fas fa-folder',
      },
      {
        types: [ResourceType.expertTip],
        icon: 'fas fa-lightbulb',
      },
      {
        types: [ResourceType.announcement],
        icon: 'fas fa-megaphone',
      },
      {
        types: [ResourceType.widget],
        icon: 'fas fa-ballot',
      },
      {
        types: [ResourceType.soundCards, ResourceType.cursiveCards],
        icon: 'fas fa-th',
      },
      {
        types: [ResourceType.file],
        icon: 'fas fa-file',
      },
      {
        types: [ResourceType.text],
        icon: 'fas fa-file-alt',
      },

      // required sub icon
      {
        types: ['true'],
        icon: 'fas fa-lock',
      },
    ];

    // store the icons in the dictionary
    sets.forEach(({ types, icon }) => {
      types.forEach((type) => {
        this.icons[type] = icon;
      });
    });
  }

  setBasicInfo(): void {
    // basics
    this.resourceNotificationService.determineNotifications(this.data);
    this.name = (this.data as Resource).name || '...';
    this.description = this.data.description;
    this.imageUrl =
      this.data.imageUrl || '/assets/img/wilson-generic-thumb.svg';
    this.actualLinkText = this.linkText;
    this.downloadable =
      this.data.type === ResourceType.pdf && this.isAttachment;
    this.flagText = this.data.notification?.show
      ? this.data.notification.text
      : null;
    this.flagClass = 'accent-flag';

    // type check
    this.isWidget =
      !this.data.requiredSubscription && this.data.type === ResourceType.widget;
  }

  overrideLinkText(): void {
    if (this.data.callToActionText) {
      this.actualLinkText = this.data.callToActionText + ' »';
    }
  }

  mapData(): void {
    this.setBasicInfo();
    if (this.isWidget) {
      this.data = this.data as WidgetResource;
    } else {
      const isExternalLink = this.data.type === ResourceType.externalLink;

      // subscription based items
      if (this.data.requiredSubscription) {
        this.href = this.data.requiredSubscription.signUpLink;
        this.flagText = 'Subscription Required';
        this.flagClass = '';
        this.actualLinkText = 'Learn More';
        this.showFooterLink = true;
      } else {
        if (isExternalLink) {
          this.href = (this.data as ExternalLinkResource).url;
          this.href = this.externalLinkMassagerPipe.transform(this.href);
        }
        this.actualLinkText = this.linkText;
        this.showFooterLink = this.footerLink;
      }

      // additional classes
      const conditions = {
        'has-flag': !!this.flagText,
        'external-link': isExternalLink,
      };
      Object.entries(conditions).forEach(([className, condition]) => {
        if (condition) {
          this.extraClasses += `${className} `;
        }
      });
    }
  }

  emitPinStatusChange(event: PinStatusChangeEvent): void {
    this.pinStatusChanged.emit(event);
  }

  editResourceClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const funnel = this.funnelService.getCurrentFunnel();
    let url = `/admin/${funnel.id}/`;
    if (this.data.id === funnel.expertTipsId) {
      url += 'tips';
    } else if (this.data.type === ResourceType.program) {
      url = `/admin/${this.data.id}/settings`;
    } else if (this.data.type === ResourceType.collection) {
      url += `collections/${this.data.id}`;
    } else {
      url += `resources/${this.data.id}`;
    }
    this.router.navigateByUrl(url);
  }

  handleClick(event: LinkTileClickEvent = null): void {
    mixpanel.track('Go to Resource', {
      'Resource Name': this.data.name,
      'Resource Type': this.data.type,
      'Resource ID': this.data.id,
    });

    let cancelled = false;
    const clickEvent: TileClickEvent = {
      data: this.data,
      originalEvent: event?.originalEvent,
      cancel: () => {
        if (event?.cancel) {
          event.cancel();
        }
        cancelled = true;
      },
    };

    this.tileClicked.emit(clickEvent);
    if (cancelled) {
      return;
    }

    // if it's downloadable, download it
    if (this.downloadable) {
      this.startDownload().subscribe();
      return;
    }

    const type = this.determineTypePathSegment();

    if (!type) {
      return;
    }

    const funnel = this.funnelService.getCurrentFunnel();

    if (this.isStandaloneResource) {
      void this.router.navigate([funnel.id, type, this.data.id]);
      return;
    }

    if (this.data.requiredSubscription && this.href) {
      window.open(this.href, '_blank');
      return;
    }

    let path = [type, this.data.id];

    if (this.routePrefix) {
      path = [this.routePrefix, ...path];
    } else if (this.isAttachment) {
      // add funnel id, absolute navigate to the resource
      const { id } = funnel;
      path = [id, ...path];
    }

    void this.router.navigate(path, {
      relativeTo: this.isAttachment ? undefined : this.route,
    });
  }

  startDownload(): Observable<HttpResponse<Blob>> {
    return this.resourceService.downloadResource(this.data);
  }

  private determineTypePathSegment(): string {
    let type = null;
    switch (this.data.type) {
      case ResourceType.collection:
        const res = this.data;
        type =
          res.type === ResourceType.collection
            ? CollectionType.collections
            : CollectionType.primitives;
        break;
      case ResourceType.program:
        this.router.navigate([this.data.id]);
        break;
      case ResourceType.externalLink:
        window.open(
          this.href,
          (this.data as ExternalLinkResource).openInNewWindow
            ? '_blank'
            : '_self'
        );
        break;
      case ResourceType.pdf:
      case ResourceType.video:
      case ResourceType.file:
      case ResourceType.text:
      case ResourceType.expertTip:
      case ResourceType.soundCards:
      case ResourceType.cursiveCards:
      case ResourceType.iframe:
        type = 'viewer';
        break;
    }
    return type;
  }
}

export interface TileClickEvent<T = BaseResource> {
  data: T;
  cancel: () => void;
  originalEvent?: MouseEvent;
}
