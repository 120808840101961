<header class="row mb-4 align-items-center">
  <div class="col" *ngIf="searchBarStyle !== searchStyle.full">
    <p-skeleton
      height="2.8rem"
      width="12rem"
      styleClass="mt-2 mb-3 mb-md-0"
      *ngIf="isLoading"
    ></p-skeleton>
    <h2
      class="page-title mb-3 mb-md-0 lh-1 lh-md-2"
      *ngIf="!isLoading"
      data-automationId="title_bar_header"
    >
      <span class="d-none d-md-inline" aria-hidden="true">
        {{ heading }}
      </span>
      <span class="d-inline d-md-none">
        {{ altHeading || heading }}
      </span>
    </h2>
  </div>
  <form
    *ngIf="searchBarStyle !== searchStyle.hidden"
    [class]="
      searchBarStyle !== searchStyle.full
        ? 'col-12 col-sm-6 col-md-5 col-lg-4'
        : 'col-12 pe-0 row justify-content-md-center mb-1 mb-md-4'
    "
    (submit)="handleSearch($event)"
  >
    <div
      class="{{
        searchBarStyle === searchStyle.full ? 'col-12 col-md-6 pe-0' : ''
      }}"
    >
      <div
        class="input-group"
        [class.input-group-lg]="searchBarStyle === searchStyle.full"
        tabindex="-1"
      >
        <label for="search" class="visually-hidden">Search</label>
        <input
          type="text"
          name="search"
          id="search"
          class="form-control"
          [placeholder]="placeholder"
          required
          [(ngModel)]="searchTerm"
          (ngModelChange)="onSearchTermChanged()"
          (keydown)="cycleFocus($event, resultList, searchInput)"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="none"
          spellcheck="false"
          #searchInput
          data-automationId="titlebar_input"
        />
        <button class="btn btn-outline-secondary btn-invisible" type="submit">
          <i class="far fa-search" aria-hidden="true"></i>
          <span class="visually-hidden">Search</span>
        </button>
        <div
          class="input-popunder"
          [class.mini]="searchBarStyle === searchStyle.mini"
          [hidden]="!searchSuggestions?.length && !searchResults?.length"
          (keydown)="cycleFocus($event, resultList, searchInput)"
          #resultList
        >
          <ul
            class="suggestions m-0 p-0 ls-none"
            *ngIf="searchSuggestions?.length"
          >
            <li *ngFor="let suggestion of searchSuggestions">
              <button
                type="button"
                class="suggestion item row no-gutters"
                (click)="suggestionClickSearch(suggestion)"
              >
                <div class="col-12">
                  <i class="far fa-search" aria-hidden="true"></i>
                  <span
                    class="popunder-highlites"
                    [innerHtml]="suggestion"
                  ></span>
                </div>
              </button>
            </li>
          </ul>
          <ng-container *ngIf="searchResults?.length">
            <hr *ngIf="searchSuggestions?.length" />
            <div class="results">
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <h2
                      class="h6 mb-0 {{
                        searchSuggestions?.length ? 'mt-0' : 'mt-2'
                      }} text-muted text-uppercase"
                    >
                      Top Results
                    </h2>
                  </div>
                </div>
              </div>

              <ul class="m-0 p-0 ls-none">
                <li *ngFor="let result of searchResults">
                  <button
                    type="button"
                    class="result item row no-gutters"
                    (click)="navigateToResource(result)"
                  >
                    <div class="col-12 col-sm-6 col-md-3">
                      <div class="image-container">
                        <span
                          class="error-text"
                          aria-hidden="true"
                          *ngIf="!result.imageUrl"
                        >
                          No image found
                        </span>
                        <img
                          *ngIf="result.imageUrl"
                          [src]="result.imageUrl"
                          (error)="result.imageUrl = null"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-9 ps-3">
                      <h3
                        class="title popunder-highlites"
                        [innerHtml]="result.name"
                      ></h3>
                      <strong
                        class="type popunder-highlites text-muted"
                        [innerHtml]="result.type | userFriendlyResourceType"
                      ></strong>
                      <p
                        class="description popunder-highlites"
                        [innerHtml]="result.description"
                      ></p>
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
  <div class="w-100 d-md-none"></div>
  <div
    *ngIf="
      !isLoading &&
      searchBarStyle !== searchStyle.full &&
      template &&
      childTemplateVisible
    "
    class="col d-flex justify-content-md-end"
  >
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </div>
</header>
