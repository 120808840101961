import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PaginatorModule } from 'primeng/paginator';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InplaceModule } from 'primeng/inplace';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FileUploadModule } from 'primeng/fileupload';
import { FieldsetModule } from 'primeng/fieldset';
import { ChipModule } from 'primeng/chip';
import { EditorModule } from 'primeng/editor';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { StepsModule } from 'primeng/steps';
import { DividerModule } from 'primeng/divider';
import { TabViewModule } from 'primeng/tabview';
import { ConfirmPopupModule } from 'primeng/confirmpopup';

import {
  CamelToSentencePipeModule,
  SanitizeUrlPipeModule,
  SanitizeHtmlPipeModule,
  StripHtmlPipeModule,
  TileModule,
  SkipToModule,
  SpinnerModule,
  DropdownModule,
} from '@wilson/wilsonng';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TreeModule } from 'primeng/tree';

import { TileGridComponent } from './components/tile-grid/tile-grid.component';
import { TileComponent } from './components/tile/tile.component';
import { PinButtonComponent } from './components/pin-button/pin-button.component';
import { WidgetComponent } from './components/widget/widget.component';
import { AcademyWidgetComponent } from './components/widget/academy-widget/academy-widget.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { SoundCardActivityComponent } from './components/sound-card-activity/sound-card-activity.component';
import { CursiveCardActivityComponent } from './components/cursive-card-activity/cursive-card-activity.component';
import { FocusOnShowDirective } from './directives/focus-on-show.directive';
import { WrsCardActivityComponent } from './components/wrs-card-activity/wrs-card-activity.component';
import { WrsCardActivityWidgetComponent } from './components/widget/wrs-card-activity-widget/wrs-card-activity-widget.component';
import { ActivitiesCanvasComponent } from './components/activities-canvas/activities-canvas.component';
import { DxCheckBoxModule, DxDataGridModule } from 'devextreme-angular';
import { ChooseUnitComponent } from './components/choose-unit/choose-unit.component';
import { BreadcrumbsComponent } from './components/navbar/breadcrumbs/breadcrumbs.component';
import { ResourceTypePipeModule } from './pipes/resource-type/resource-type.pipe.module';
import { ExternalLinkMassagerPipeModule } from './pipes/external-link-massager/external-link-massager.pipe.module';

@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbsComponent,
    FooterComponent,
    NavbarComponent,
    TitleBarComponent,
    TileGridComponent,
    PinButtonComponent,
    TileComponent,
    WidgetComponent,
    AcademyWidgetComponent,
    WrsCardActivityWidgetComponent,
    SoundCardActivityComponent,
    CursiveCardActivityComponent,
    WrsCardActivityComponent,
    ActivitiesCanvasComponent,
    ChooseUnitComponent,

    // directives
    FocusOnShowDirective,
  ],
  imports: [
    // angular
    CommonModule,
    DragDropModule,

    // primeng
    BreadcrumbModule,
    InputSwitchModule,
    RadioButtonModule,
    MenuModule,
    ButtonModule,
    SplitButtonModule,
    DialogModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    SpinnerModule,
    PaginatorModule,
    ToastModule,
    InplaceModule,
    InputTextModule,
    InputTextareaModule,
    ConfirmDialogModule,
    SidebarModule,
    SkeletonModule,
    TooltipModule,
    TableModule,
    FileUploadModule,
    FieldsetModule,
    ChipModule,
    EditorModule,
    CheckboxModule,
    OverlayPanelModule,
    AutoCompleteModule,
    AccordionModule,
    ToggleButtonModule,
    TreeModule,
    StepsModule,
    DividerModule,
    TabViewModule,
    ConfirmPopupModule,

    // third party
    NgxExtendedPdfViewerModule,
    // devexpress
    DxDataGridModule,
    DxCheckBoxModule,

    //pipes
    ResourceTypePipeModule,
    ExternalLinkMassagerPipeModule,

    // wilsonng
    CamelToSentencePipeModule,
    SanitizeUrlPipeModule,
    SanitizeHtmlPipeModule,
    StripHtmlPipeModule,
    TileModule,
    SkipToModule,
    DropdownModule,
  ],
  exports: [
    BreadcrumbModule,
    InputSwitchModule,
    RadioButtonModule,
    MenuModule,
    ButtonModule,
    SplitButtonModule,
    DialogModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    SpinnerModule,
    PaginatorModule,
    ToastModule,
    InplaceModule,
    InputTextModule,
    InputTextareaModule,
    ConfirmDialogModule,
    SidebarModule,
    SkeletonModule,
    TooltipModule,
    TableModule,
    FileUploadModule,
    FieldsetModule,
    ChipModule,
    EditorModule,
    CheckboxModule,
    OverlayPanelModule,
    AutoCompleteModule,
    AccordionModule,
    ToggleButtonModule,
    TreeModule,
    StepsModule,
    DividerModule,
    TabViewModule,
    ConfirmPopupModule,

    // third party
    NgxExtendedPdfViewerModule,
    // devexpress
    DxDataGridModule,
    DxCheckBoxModule,

    // wilsonng
    CamelToSentencePipeModule,
    SanitizeUrlPipeModule,
    SanitizeHtmlPipeModule,
    StripHtmlPipeModule,
    ResourceTypePipeModule,
    TileModule,
    SkipToModule,
    DropdownModule,

    // app components
    HeaderComponent,
    BreadcrumbsComponent,
    FooterComponent,
    NavbarComponent,
    TitleBarComponent,
    TileGridComponent,
    PinButtonComponent,
    TileComponent,
    WidgetComponent,
    AcademyWidgetComponent,
    WrsCardActivityWidgetComponent,
    SoundCardActivityComponent,
    CursiveCardActivityComponent,
    WrsCardActivityComponent,
    ActivitiesCanvasComponent,
    ChooseUnitComponent,

    // directives
    FocusOnShowDirective,
  ],
})
export class UIModule {}
