<canvas
  #canvas
  class="{{ showCanvas ? '' : 'd-none' }}"
  (mousedown)="mouseStart($event)"
  (touchstart)="touchStart($event)"
  (mousemove)="draw($event)"
  (touchmove)="touchDraw($event)"
  (mouseup)="endEvent()"
  (touchend)="endEvent()"
  (mouseout)="endEvent()"
></canvas>
