import { Component } from '@angular/core';

@Component({
  selector: 'app-cursive-card-activity',
  templateUrl: './cursive-card-activity.component.html',
  styleUrls: ['./cursive-card-activity.component.scss'],
})
export class CursiveCardActivityComponent {
  activeVideo: string;
  activeTab = 0;
  videoBase = `/assets/cursive/`;
  group = CursiveVideoGroup;

  // data
  introVideos = [
    {
      label: 'Introduce Letter',
      path: 'introduce.mp4',
    },
    {
      label: 'Skywrite Letter',
      path: 'skywrite.mp4',
    },
    {
      label: 'Dry Erase',
      path: 'dryerase.mp4',
    },
  ];
  letters = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];

  playVideo(url: string, group: CursiveVideoGroup): void {
    this.activeVideo = `${this.videoBase}${group}${url}`;
  }

  updateActiveTab(event: { originalEvent: MouseEvent; index: number }): void {
    this.activeTab = event.index;
    this.activeVideo = null;
  }
}

export enum CursiveVideoGroup {
  Intro = '',
  Lowercase = 'lowercase/',
  Uppercase = 'uppercase/',
}
