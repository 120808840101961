import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UtilityService } from '@wilson/wilsonng';
import mixpanel from 'mixpanel-browser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FunnelService } from 'src/app/services/funnel.service';
import { Wilson } from 'src/def/Wilson';
import ProgramResource = Wilson.ProgramResource;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, AfterViewInit {
  copyrightYears: string;
  sitemapLink: string;

  constructor(
    private utilityService: UtilityService,
    private funnelService: FunnelService
  ) {}

  ngOnInit(): void {
    this.setSitemapLink().subscribe();
    this.copyrightYears = this.utilityService.getCopyrightYearsString(2021);
  }

  setSitemapLink(): Observable<ProgramResource> {
    return this.funnelService.watchCurrentFunnel().pipe(
      tap((funnel) => {
        if (funnel) {
          this.sitemapLink = `${funnel.id}/sitemap`;
        }
      })
    );
  }

  /* istanbul ignore next */
  ngAfterViewInit(): void {
    this.mixPanelTracking();
  }

  /* istanbul ignore next */
  mixPanelTracking(): void {
    mixpanel.track_links('#btnTermsAndConditions', 'Go to Term and Conditions');
    mixpanel.track_links('#btnPrivacyPolicy', 'Go to Privacy Policy');
    mixpanel.track_links('#btnSiteMap', 'Go to Site Map');
  }
}
