<div class="tile-grid">
  <div
    class="row"
    cdkDropList
    [cdkDropListData]="tileSource"
    cdkDropListSortingDisabled
    [cdkDropListDisabled]="!draggable"
    [@gridState]="state"
    (@gridState.done)="canChange($event)"
  >
    <div
      class="tile-column {{ columnClass }}"
      *ngFor="
        let tile of tileSource || [];
        trackBy: getTrackingId;
        let i = index
      "
      [style.z-index]="(tileSource || []).length - i"
      cdkDrag
      [cdkDragData]="tile"
      [cdkDragDisabled]="!draggable"
    >
      <div *cdkDragPreview class="soft-shadow drag-box">
        <h4>{{ tile.name }}</h4>
        <p>{{ tile.description.substring(0, 30) }}...</p>
      </div>
      <div cdkDragHandle>
        <app-tile
          [data]="tile"
          [linkText]="
            linkText ||
            'Open ' +
              (tile?.type === types.iframe
                ? ''
                : (tile?.type || '' | userFriendlyResourceType : true)) +
              ' &raquo;'
          "
          styleClass="{{ tileClass }} {{
            multiShareIds.includes(tile.id) ? 'selected' : ''
          }}"
          [showPin]="showPin"
          [isMultiShareModeActive]="isMultiShareModeActive"
          [isStandaloneResource]="isStandaloneResource"
          [routePrefix]="routePrefix"
          (tileClicked)="handleTileClick($event)"
          (pinStatusChanged)="emitPinStatusChange($event)"
        >
          <ng-container ngProjectAs="'[tile-header]'">
            <ng-container
              *ngTemplateOutlet="template; context: { data: tile }"
            ></ng-container>
          </ng-container>
        </app-tile>
      </div>
    </div>
  </div>
  <div class="col-12 tile-grid-pager" *ngIf="totalItems > pageSize">
    <p-paginator
      [rows]="pageSize"
      [first]="pageSize * (page - 1)"
      [totalRecords]="totalItems"
      (onPageChange)="onPageChange($event)"
    ></p-paginator>
  </div>
</div>
