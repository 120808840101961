<main class="container" id="wrs-card-activity">
  <app-activities-canvas
    [drawingEnabled]="drawingEnabled"
    [canvasSize]="actZone"
  ></app-activities-canvas>
  <div
    #activityZone
    class="activity-container d-none d-lg-block"
    [class.keyboard-mode]="keyboardModeEnabled"
    (touchmove)="getTouchPreviewOffset($event)"
    cdkDropListGroup
  >
    <ng-container *ngIf="!selectedUnit?.disabled">
      <div class="drop-zone" #dropZone (mousemove)="getPreviewOffset($event)">
        <div
          #frameRow
          class="frame-row"
          *ngFor="
            let row of dropRows;
            let rowIndex = index;
            trackBy: getTrackingIndex
          "
          cdkDropList
          (cdkDropListDropped)="handleDrop($event, rowIndex, frameRow)"
        >
          <!-- KEYBOARD MODE -->
          <div class="item-list" *ngIf="keyboardModeEnabled">
            <ng-container
              *ngFor="
                let item of combinedRows[rowIndex];
                let itemIndex = index;
                trackBy: getTrackingId
              "
            >
              <!-- card -->
              <div
                *ngIf="!item.cards"
                class="wlt-card wrs-card"
                [style]="{
                  background: item.color,
                  left: item.xOffset + 'px'
                }"
                [attr.data-index]="itemIndex"
                [attr.data-id]="item.id"
                tabIndex="0"
                (keydown)="
                  handleKeyDown($event, item, ds.dropRow, itemIndex, rowIndex)
                "
              >
                <ng-container *ngIf="!item.isBlankCard">
                  {{ item.text || '&nbsp;' }}
                </ng-container>
                <input
                  spellcheck="false"
                  autocomplete="off"
                  class="wlt-manuscript"
                  type="text"
                  *ngIf="item.isBlankCard"
                  [(ngModel)]="item.text"
                  (keydown)="$event.stopPropagation()"
                  maxlength="4"
                />
              </div>

              <!-- frame -->
              <div
                *ngIf="item.cards"
                class="frame {{ item.type }}-frame"
                [attr.data-index]="itemIndex"
                [attr.data-id]="item.id"
                [attr.tabindex]="keyboardModeEnabled ? 0 : -1"
                (keydown)="
                  handleKeyDown($event, item, ds.dropRow, itemIndex, rowIndex)
                "
                [style]="{
                  left: item.xOffset + 'px'
                }"
              >
                <div
                  class="frame-input"
                  [class.no-hide]="item.text"
                  *ngIf="!item.cards?.length"
                >
                  <input
                    type="text"
                    maxlength="8"
                    class="wlt-manuscript"
                    spellcheck="false"
                    autocomplete="off"
                    (keydown)="$event.stopPropagation()"
                    [(ngModel)]="item.text"
                  />
                </div>

                <!-- this exists here only for pseudo-element attachment -->
                <p class="info"></p>

                <!-- cards -->
                <div class="frame-cards" *ngIf="item.cards.length">
                  <div
                    class="wlt-card wrs-card"
                    [style]="{
                      background: card.color,
                      left: card.xOffset + 'px'
                    }"
                    [attr.tabindex]="keyboardModeEnabled ? 0 : -1"
                    [attr.data-id]="card.id"
                    [attr.data-index]="cardIndex"
                    (keydown)="
                      handleKeyDown(
                        $event,
                        card,
                        ds.frame,
                        cardIndex,
                        rowIndex,
                        itemIndex
                      )
                    "
                    *ngFor="
                      let card of item.cards;
                      let cardIndex = index;
                      trackBy: getTrackingId
                    "
                  >
                    <ng-container *ngIf="!card.isBlankCard">
                      {{ card.text || '&nbsp;' }}
                    </ng-container>
                    <input
                      spellcheck="false"
                      autocomplete="off"
                      class="wlt-manuscript"
                      type="text"
                      *ngIf="card.isBlankCard"
                      [(ngModel)]="card.text"
                      (keydown)="$event.stopPropagation()"
                      maxlength="4"
                    />
                  </div>
                </div>

                <!-- close button -->
                <button
                  class="close-btn"
                  title="Remove frame"
                  (click)="closeFrame(item, rowIndex, itemIndex)"
                  (keydown)="$event.stopPropagation()"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </ng-container>
          </div>

          <!-- NON KEYBOARD MODE -->
          <ng-container *ngIf="!keyboardModeEnabled">
            <!-- hold cards on each row -->
            <div class="card-list">
              <div
                cdkDrag
                (cdkDragStarted)="setDragSource(ds.dropRow, rowIndex)"
                [cdkDragData]="card"
                class="wlt-card wrs-card"
                [style]="{
                  background: card.color,
                  left: card.xOffset + 'px',
                  top:
                    rowIndex === 0 && card.yOffset
                      ? card.yOffset + 'px'
                      : 'unset'
                }"
                [attr.data-index]="cardIndex"
                [attr.data-id]="card.id"
                *ngFor="
                  let card of dropRows[rowIndex].cards;
                  let cardIndex = index;
                  trackBy: getTrackingId
                "
              >
                <ng-container *ngIf="!card.isBlankCard">
                  {{ card.text || '&nbsp;' }}
                </ng-container>
                <input
                  spellcheck="false"
                  autocomplete="off"
                  class="wlt-manuscript"
                  type="text"
                  *ngIf="card.isBlankCard"
                  [(ngModel)]="card.text"
                  maxlength="4"
                />
              </div>
            </div>

            <!-- hold frames on each row -->
            <div class="frame-list" *ngIf="!keyboardModeEnabled">
              <div
                cdkDrag
                (cdkDragStarted)="
                  setDragSource(ds.dropRow, rowIndex, frameIndex)
                "
                [cdkDragData]="frame"
                *ngFor="
                  let frame of dropRows[rowIndex].frames;
                  let frameIndex = index;
                  trackBy: getTrackingId
                "
                class="frame {{ frame.type }}-frame"
                [attr.data-index]="frameIndex"
                [attr.data-id]="frame.id"
                [style]="{
                  left: frame.xOffset + 'px'
                }"
              >
                <div
                  class="frame-input"
                  [class.no-hide]="frame.text"
                  *ngIf="!frame.cards.length"
                >
                  <input
                    type="text"
                    maxlength="8"
                    class="wlt-manuscript"
                    spellcheck="false"
                    autocomplete="off"
                    [(ngModel)]="frame.text"
                  />
                </div>

                <!-- this exists here only for pseudo-element attachment -->
                <p class="info"></p>

                <!-- cards -->
                <div class="frame-cards" *ngIf="frame.cards.length">
                  <div
                    cdkDrag
                    (cdkDragStarted)="
                      setDragSource(ds.frame, rowIndex, frameIndex)
                    "
                    [cdkDragData]="card"
                    class="wlt-card wrs-card"
                    [style]="{
                      background: card.color,
                      left: card.xOffset + 'px'
                    }"
                    [attr.data-id]="card.id"
                    [attr.data-index]="cardIndex"
                    *ngFor="
                      let card of frame.cards;
                      let cardIndex = index;
                      trackBy: getTrackingId
                    "
                  >
                    <ng-container *ngIf="!card.isBlankCard">
                      {{ card.text || '&nbsp;' }}
                    </ng-container>
                    <input
                      spellcheck="false"
                      autocomplete="off"
                      class="wlt-manuscript"
                      type="text"
                      *ngIf="card.isBlankCard"
                      [(ngModel)]="card.text"
                      maxlength="4"
                    />
                  </div>
                </div>

                <!-- close button -->
                <button
                  class="close-btn"
                  title="Remove frame"
                  (click)="closeFrame(frame, rowIndex, frameIndex)"
                  (keydown)="$event.stopPropagation()"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- source -->
      <div
        #dragZone
        class="drag-zone mt-3 {{ gridSize }} {{ actionsClass }}"
        *ngIf="selectedUnit"
      >
        <div class="cards" [style]="{ 'font-size': fontSize }">
          <div
            class="card-row"
            cdkDropList
            cdkDropListSortingDisabled
            (cdkDropListDropped)="removeCard($event)"
            *ngFor="let cards of cardRows; trackBy: getTrackingId"
          >
            <div
              #wrsCard
              class="card-column"
              [cdkDropListData]="cards"
              *ngFor="let card of cards; trackBy: getTrackingId"
            >
              <div
                cdkDrag
                (cdkDragStarted)="setDragSource(ds.cards)"
                [cdkDragData]="card"
                *ngIf="card.color"
                class="wlt-card wrs-card"
                [attr.data-blank]="card.isBlankCard"
                [attr.tabindex]="keyboardModeEnabled ? 0 : -1"
                (keydown)="handleKeyDown($event, card, ds.cards)"
                [style]="{ background: card.color }"
              >
                <ng-container *ngIf="!card.isBlankCard">
                  {{ card.text || '&nbsp;' }}
                </ng-container>
                <input
                  spellcheck="false"
                  autocomplete="off"
                  class="wlt-manuscript"
                  type="text"
                  *ngIf="card.isBlankCard"
                  (keydown)="$event.stopPropagation()"
                  [(ngModel)]="card.text"
                  maxlength="4"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="actions">
          <div class="buttons mb-2" *ngIf="!keyboardModeEnabled">
            <button
              class="btn btn-primary me-2 mb-2"
              (click)="clearDropRows(true)"
            >
              <i class="fas fa-folder-times" aria-hidden="true"></i>
              Clear All
            </button>
            <button class="btn btn-primary mb-2" (click)="clearDropRows()">
              <i class="fas fa-times" aria-hidden="true"></i>
              Clear Cards
            </button>
          </div>
          <button
            class="actions-toggle btn btn-secondary {{
              framesStatus ? 'hide' : 'show'
            }}"
            (click)="toggleFrames()"
          >
            <i
              class="show-icon far fa-angle-double-left"
              aria-hidden="true"
            ></i>
            {{ framesStatus ? 'Hide' : 'Show' }} Frames
            <i
              class="hide-icon far fa-angle-double-right"
              aria-hidden="true"
            ></i>
          </button>
          <div
            class="frame-group-container"
            [class.show]="framesStatus"
            *ngIf="frameSections.length"
          >
            <div class="frame-box" *ngFor="let section of frameSections">
              <span class="group-title" *ngIf="section.title">
                {{ section.title }}
              </span>
              <div
                cdkDropList
                [cdkDropListData]="group"
                cdkDropListSortingDisabled
                (cdkDropListDropped)="removeCard($event)"
                class="frame-group"
                *ngFor="let group of section.groups"
              >
                <div
                  cdkDrag
                  (cdkDragStarted)="setDragSource(ds.frames)"
                  [cdkDragData]="frame"
                  *ngFor="
                    let frame of group.frames;
                    let frameIndex = index;
                    trackBy: getTrackingId
                  "
                  class="frame {{ frame.type }}-frame"
                  (keydown)="handleKeyDown($event, frame, ds.frames)"
                  [attr.tabindex]="
                    keyboardModeEnabled &&
                    frameIndex === group.frames.length - 1
                      ? 0
                      : -1
                  "
                >
                  <div
                    class="frame-input"
                    [class.no-hide]="frame.text"
                    *ngIf="frameIndex === group.frames.length - 1"
                  >
                    <label
                      class="visually-hidden"
                      for="{{ frame.type }}-{{ frameIndex }}"
                    >
                      Frame Text
                    </label>
                    <input
                      type="text"
                      maxlength="8"
                      spellcheck="false"
                      class="wlt-manuscript"
                      autocomplete="off"
                      [(ngModel)]="frame.text"
                      (keydown)="$event.stopPropagation()"
                      id="{{ frame.type }}-{{ frameIndex }}"
                    />
                  </div>
                  <p class="info" [class.hide]="frame.text">
                    {{ frame.type | camelToSentence }} Frames
                    <span>Drag above to add</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedUnit?.disabled">
      <div class="inactive-message">
        <h3>Uh-oh!</h3>
        <p>
          The selected level is not available at this time. Please check again
          later.
        </p>
      </div>
    </ng-container>
  </div>

  <div class="activity-container d-block d-lg-none text-center">
    <h2>Hmm... looks like your screen is too small!</h2>
    <p>
      Your browser is currently too small to display this activity correctly.
      Please adjust the size of your browser window, magnification, or device
      settings to accommodate.
    </p>
    <h3 class="d-md-none">Using a smart device?</h3>
    <p class="d-md-none">
      If you are using a tablet or smartphone, please switch to a device with a
      larger screen.
    </p>
  </div>
</main>
