<section class="mt-1 mb-4" id="cursive-cards">
  <div class="row">
    <p-tabView styleClass="col-12" (onChange)="updateActiveTab($event)">
      <p-tabPanel header="Introduction">
        <div class="row">
          <div class="col-12 col-md-7 mb-3 mb-md-0">
            <p>
              Select the above tabs to view upper- and lowercase letter
              demonstrations. Use these explicit verbalizations when directing
              your students in proper cursive letter formation.
            </p>
            <p class="mb-4">
              Select a video below to view letter formation procedures using the
              <strong>Wilson&copy; Writing Grid</strong> , skywriting or
              <strong>dry erase boards</strong> in a classroom setting.
            </p>
            <div class="mb-2" *ngFor="let video of introVideos">
              <button
                type="button"
                class="btn btn-primary"
                [class.active]="activeVideo?.endsWith(video.path)"
                (click)="playVideo(video.path, group.Intro)"
              >
                {{ video.label }}
              </button>
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="video-container">
              <p>Select a video to get started</p>
              <ng-container *ngIf="activeTab === 0">
                <video
                  [hidden]="!activeVideo"
                  [attr.src]="activeVideo"
                  autoplay
                  controls
                ></video>
              </ng-container>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Lowercase">
        <div class="row">
          <div class="col-12 col-md-7 mb-3 mb-md-0">
            <h4>Lowercase Cursive Letter Formation</h4>
            <p class="mb-4">Click a letter to view its formation.</p>
            <div class="letter-buttons">
              <button
                *ngFor="let letter of letters"
                type="button"
                class="btn btn-primary"
                [class.active]="activeVideo?.endsWith(letter + '.mp4')"
                (click)="playVideo(letter + '.mp4', group.Lowercase)"
              >
                {{ letter }}
              </button>
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="video-container cursive-grid">
              <img src="/assets/img/cursive-grid.svg" aria-hidden="true" />
              <ng-container *ngIf="activeTab === 1">
                <video
                  [hidden]="!activeVideo"
                  [attr.src]="activeVideo"
                  autoplay
                ></video>
              </ng-container>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Uppercase">
        <div class="row">
          <div class="col-12 col-md-7 mb-3 mb-md-0">
            <h4>Uppercase Cursive Letter Formation</h4>
            <p class="mb-4">Click a letter to view its formation.</p>
            <div class="letter-buttons">
              <button
                *ngFor="let letter of letters"
                type="button"
                class="btn btn-primary"
                [class.active]="activeVideo?.endsWith(letter + '.mp4')"
                (click)="playVideo(letter + '.mp4', group.Uppercase)"
              >
                {{ letter.toUpperCase() }}
              </button>
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="video-container cursive-grid">
              <img src="/assets/img/cursive-grid.svg" aria-hidden="true" />
              <ng-container *ngIf="activeTab === 2">
                <video
                  [hidden]="!activeVideo"
                  [attr.src]="activeVideo"
                  autoplay
                ></video>
              </ng-container>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</section>
