<wilson-tile
  imageSrc="../../../assets/img/tiles/UnitPickerWidget.png"
  [flagText]="flagText"
  [flagClass]="flagClass"
  [styleClass]="styleClass"
>
  <h4 class="tile-heading">{{ data.name }}</h4>
  <p class="tile-description">{{ data.description }}</p>
  <wilson-dropdown
    [placeholder]="placeholder"
    id="unitChooser"
    class="w-100"
    [(ngModel)]="selectedUnit"
    [options]="units"
    optionLabel="title"
    (ngModelChange)="navigate()"
    data-automationId="client_widget_unit_chooser"
  ></wilson-dropdown>
</wilson-tile>
