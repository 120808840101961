import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  private determineCardColor(cardData: string): CardColor {
    const firstChar = cardData[0];
    let color = CardColor.default;
    switch (firstChar) {
      case '+':
        color = CardColor.salmon;
        break;
      case '-':
        color = CardColor.green;
        break;
      case '*':
        color = CardColor.white;
        break;
    }

    return color;
  }

  generateCard(cardData: string, isSoundCard = false): Card {
    const color = this.determineCardColor(cardData);
    const filePath = cardData.replace(/[+\-\*]/g, '');
    const text = filePath.replace('_vowel', '').replace(/[0-9_]/g, '');
    return {
      id: '', // this id will get filled in when the card gets placed in a drop row
      color: cardData ? color : null,
      text,
      isHidden: false,
      filePath:
        filePath && isSoundCard ? `/assets/audio/${filePath}.mp3` : null,
      xOffset: 0,
      yOffset: 0,
      isBlankCard: !text,
    };
  }

  getKey(code: string): string {
    return code.replace(/(key|digit)/gi, '');
  }

  toBind(code: string): KeyBind {
    for (let key in KeyBind) {
      if (KeyBind[key] === code) {
        return KeyBind[key];
      }
    }

    return null;
  }
}

// update this to change what key need to be pressed
// ALT is used when triggering actions
// SHIFT is used to move focus around
export enum KeyBind {
  // actions
  HelpToggle = 'KeyH',
  KeyboardToggle = 'KeyK',
  ClearItems = 'KeyX',
  DeleteItem = 'Delete',

  // movement
  MoveUp = 'ArrowUp',
  MoveDown = 'ArrowDown',
  MoveLeft = 'ArrowLeft',
  MoveRight = 'ArrowRight',

  // focus
  DropRow1 = 'Digit1',
  DropRow2 = 'Digit2',
  FirstCard = 'KeyC',
  FirstBlankCard = 'KeyB',
  FirstFrame = 'KeyF',
  NextRow = 'KeyN',
  PrevRow = 'KeyP',
  StepSelector = 'KeyS',

  // Arbitrary keys targeted specifically
  Quote = 'Quote',
}

export enum CardColor {
  default = '#FAE79A',
  salmon = '#EDC7BC',
  green = '#C1D7AC',
  white = '#FFF',
}

export interface Card {
  id: string;
  text: string;
  color: CardColor;
  isHidden: boolean;
  isBlankCard: boolean;
  filePath: string;
  xOffset?: number;
  yOffset?: number;
}

export interface CardFrame {
  id: string;
  cards: Card[];
  text: string;
  type: CardFrameType;
  xOffset: number;
}

export enum CardFrameType {
  syllable = 'syllable',
  baseElement = 'baseElement',
  greekStart = 'greekStart',
  greekEnd = 'greekEnd',
  greekMid = 'greekMid',
  suffix = 'suffix',
  prefix = 'prefix',
}

export interface CardFrameGroup {
  items: CardFrameType[];
  title?: string;
}

export interface CardUnit {
  title: string;
  rows: string[];
  frames?: CardFrameGroup[];
  disabled?: boolean;
}
