<section class="mt-3" id="sound-cards">
  <div class="row">
    <div class="col-12">
      <wilson-dropdown
        *ngIf="showFunnelSelector"
        [options]="funnelOptions"
        [(ngModel)]="selectedFunnel"
        (ngModelChange)="checkQueryParams()"
        styleClass="mb-3 me-3 d-inline-block sound-card-dropdown"
      ></wilson-dropdown>
      <wilson-dropdown
        *ngIf="currentLevel?.length > 1"
        [options]="currentLevel"
        [(ngModel)]="selectedUnit"
        (ngModelChange)="updateUnitFilter()"
        optionLabel="title"
        styleClass="mb-3 d-inline-block step-dropdown"
      ></wilson-dropdown>
    </div>
  </div>
  <div class="row justify-content-center" cdkDropListGroup>
    <div class="col-12 col-md-4 col-lg-3" *ngIf="cardRows?.length">
      <div
        class="card-dropzone mb-4"
        cdkDropList
        [cdkDropListDisabled]="activeCard"
        (cdkDropListDropped)="drop($event)"
      >
        <button
          *ngIf="activeCard"
          class="wlt-card sound-card"
          [style]="{ background: activeCard.color }"
          (click)="playActiveCardAudio()"
        >
          {{ activeCard.text }}
        </button>
        <p class="drop-hint" *ngIf="!activeCard">
          Drag a card into this place holder to hear its letter-keyword-sound
        </p>
        <button
          (click)="clearActiveCard()"
          pButton
          class="btn-primary mt-5"
          *ngIf="activeCard"
          label="Clear"
        ></button>
      </div>
    </div>
    <div class="col-auto d-none d-md-block">
      <div
        class="card-row"
        *ngFor="let cards of cardRows; trackBy: getRowIndex"
        cdkDropList
        [cdkDropListDisabled]="activeCard"
        cdkDropListSortingDisabled
      >
        <div class="card-column" *ngFor="let card of cards">
          <div
            cdkDrag
            [cdkDragData]="card"
            *ngIf="card.text && !card.isHidden"
            class="wlt-card sound-card"
            (click)="setActiveCard(card)"
            [style]="{ background: card.color }"
          >
            {{ card.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 d-block d-md-none mt-3">
      <div
        class="row"
        cdkDropList
        [cdkDropListDisabled]="activeCard"
        cdkDropListSortingDisabled
      >
        <ng-container *ngFor="let cards of cardRows; trackBy: getRowIndex">
          <ng-container *ngFor="let card of cards">
            <div class="col-3 mb-4" *ngIf="card.text && !card.isHidden">
              <div
                cdkDrag
                [cdkDragData]="card"
                class="sound-card soft-shadow"
                [style]="{ background: card.color }"
              >
                {{ card.text }}
                <audio
                  preload="auto"
                  [autoplay]="false"
                  [src]="card.filePath"
                  class="d-none"
                ></audio>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</section>
