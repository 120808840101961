import { Component, Input } from '@angular/core';
import { Wilson } from 'src/def/Wilson';
import WidgetType = Wilson.WidgetType;
import WidgetResource = Wilson.WidgetResource;

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: [],
})
export class WidgetComponent {
  @Input() data: WidgetResource;
  @Input() flagText = '';
  @Input() flagClass = '';
  @Input() styleClass = '';

  // used in the markup
  widgetType = WidgetType;
}
