import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Data, Event, NavigationEnd, Router } from '@angular/router';
import { RouterNavigationService, TitleService } from '@wilson/wilsonng';
import mixpanel from 'mixpanel-browser';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent implements OnInit {
  home: MenuItem;
  path: MenuItem[] = [];

  constructor(
    private router: Router,
    private titleService: TitleService,
    private routerNavigationService: RouterNavigationService
  ) {}

  ngOnInit(): void {
    this.getBreadcrumbs().pipe(switchMap(this.watchRoute)).subscribe();
  }

  getBreadcrumbs = (): Observable<MenuItem[]> =>
    this.routerNavigationService.getDeepestFirstChild().data.pipe(
      map((data: Data) => data?.breadcrumb),
      tap((breadcrumb: MenuItem[]) => {
        this.path = breadcrumb.slice(1);
        this.home = {
          ...breadcrumb[0],
          icon: 'pi pi-home',
          label: '',
        };

        this.titleService.setPageName(breadcrumb[breadcrumb.length - 1].label);
      })
    );

  watchRoute = (): Observable<MenuItem[]> =>
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      switchMap(this.getBreadcrumbs)
    );

  breadcrumbClicked(event: any) {
    if (event.item.label === 'Home') {
      mixpanel.track('Go to Landing Page');
    } else {
      mixpanel.track('Go to Resource', {
        'Resource Name': event.item.label,
        'Resource Type': event.item.type,
        'Resource Id': event.item.id,
      });
    }
  }
}
