import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { debounce } from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Selectors } from 'src/app/pages/card-activities/card-activities.component';
import {
  Card,
  CardFrame,
  CardFrameType,
  CardService,
  CardUnit,
  KeyBind,
} from 'src/app/services/card.service';

@Component({
  selector: 'app-wrs-card-activity',
  templateUrl: './wrs-card-activity.component.html',
  styleUrls: ['./wrs-card-activity.component.scss'],
})
export class WrsCardActivityComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() drawingEnabled = false;
  @Input() keyboardModeEnabled = false;
  @Input() step: string;
  @Output() message = new EventEmitter<string>();
  @ViewChild('dropZone') dropZone: ElementRef<HTMLDivElement>;
  @ViewChild('dragZone') dragZone: ElementRef<HTMLDivElement>;
  @ViewChild('activityZone', { static: true })
  activityZone: ElementRef<HTMLElement>;
  @ViewChildren('wrsCard') wrsCards: QueryList<ElementRef>;
  actZone = { height: 0, width: 0 };
  units: CardUnit[];
  selectedUnit: CardUnit;
  cardRows: Card[][];
  frameSections = [] as FrameSection[];
  dropRows: DropRow[];
  gridSize: string;
  fontSize: string;
  debounceFontSize = debounce(() => {
    this.setFontSize();
  }, 100);
  actionsClass: string;
  framesStatus = true;

  // mouse coords for delta calculation
  preview: HTMLDivElement;
  offsetX = 0;
  offsetY = 0;
  spacing = 8;

  // hold source for transfer
  activeSource: DragSource;
  activeRow = -1;
  activeFrame = -1;

  /** the number of each frame to generate */
  frameStackSize = 3;

  // key sequence for card cycling
  keySequence: string[] = [];
  cardElements: HTMLElement[];
  movementSize = 50;
  itemSpacing = 5;

  /** used to combine lists for show during keyboard mode */
  combinedRows: CombinedRow[];

  // used in the markup
  ds = DragSource;

  constructor(
    private apiService: ApiService,
    private cardService: CardService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getCanvasSize();
    this.getCards().subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.units &&
      changes.step?.currentValue !== changes.step?.previousValue
    ) {
      this.processUnitData();
    }

    if (this.drawingEnabled) {
      this.getCanvasSize();
    }

    if (this.keyboardModeEnabled) {
      this.clearDropRows(true);
    }
  }

  ngAfterViewInit(): void {
    this.wrsCards.changes.subscribe(() => {
      this.debounceFontSize();
    });
  }

  getTrackingIndex(index: number): number {
    return index;
  }

  getTrackingId(index: number, item: Card | CardFrame): string {
    return item.id;
  }

  initDropRows(): void {
    this.dropRows = [
      { cards: [], frames: [] },
      { cards: [], frames: [] },
    ];
    this.combinedRows = [[], []];
  }

  getCanvasSize(): void {
    this.actZone.width = this.activityZone.nativeElement.clientWidth;
    this.actZone.height = this.activityZone.nativeElement.clientHeight;
  }

  private getSelectedUnit(): void {
    let unit = this.units.find((u) => u.title === this.step);
    if (!unit) {
      unit = this.units[0];
      this.step = unit.title;
    }

    this.initDropRows();
    this.selectedUnit = unit;
  }

  private getUnitFrames(): void {
    this.frameSections = [];
    this.selectedUnit.frames.forEach((group) => {
      let groupFrames = [] as CardFrameGroup[];
      group.items.forEach((item) => {
        let typeFrames = [] as CardFrame[];
        for (let i = 0; i < this.frameStackSize; i++) {
          typeFrames = [
            ...typeFrames,
            {
              id: `frame-${item}-${i}`, // this id will get overriden when the frame is added to a drop row
              cards: [],
              text: '',
              type: item,
              xOffset: 0,
            },
          ];
        }

        groupFrames = [
          ...groupFrames,
          {
            type: item,
            frames: [...typeFrames],
          },
        ];
      });

      this.frameSections = [
        ...this.frameSections,
        {
          title: group.title,
          groups: [...groupFrames],
        },
      ];
    });

    this.actionsClass = this.frameSections.length === 2 ? 'wide-frames' : '';
    this.framesStatus = true;
  }

  getCards(): Observable<CardUnit[]> {
    return this.apiService
      .getLocalAsset<CardUnit[]>(`/assets/data/wrs-cards.json`)
      .pipe(
        tap((data) => {
          this.units = data;
          this.processUnitData();
        })
      );
  }

  processUnitData(): void {
    let colsCount = [];
    this.getSelectedUnit();
    this.getUnitFrames();
    this.cardRows = this.selectedUnit.rows.map((rowData) => {
      const cols = rowData.split(',');
      colsCount = [...colsCount, cols.length];
      return cols.map((cardData) =>
        this.cardService.generateCard(cardData.trim())
      );
    });

    this.determineGridSize(colsCount);
  }

  /** determine the gridSize based on the maximum
   * number of columns in selected step
   */
  determineGridSize(colsCount: number[]): void {
    const maxCols = Math.max(...colsCount);
    switch (true) {
      case maxCols < 11:
        this.gridSize = 'grid-sm';
        break;
      case maxCols >= 11 && maxCols < 16:
        this.gridSize = 'grid-md';
        break;
      case maxCols >= 16:
        this.gridSize = 'grid-lg';
        break;
      default:
        this.gridSize = 'grid-md';
    }
  }

  /** use debounce to set the font size once after
   *  a resize instead of once per pixel during the resize
   */
  @HostListener('window:resize')
  onWindowResize(): void {
    this.debounceFontSize();
  }

  setFontSize(): void {
    const card = this.dragZone.nativeElement.querySelector(Selectors.Card);
    const relFontsize = card.clientWidth * 0.2;
    this.fontSize = relFontsize + 'pt';
  }

  toggleFrames(): void {
    this.framesStatus = !this.framesStatus;
  }

  clearDropRows(clearFrames = false): void {
    // clear cards
    for (let row of this.dropRows) {
      row.cards = [];
      row.frames = row.frames.map((frame) => {
        frame.cards = [];
        return frame;
      });
    }

    // clear frames
    if (clearFrames) {
      for (let row of this.dropRows) {
        row.frames = [];
      }
    }

    if (this.keyboardModeEnabled) {
      this.combinedRows = this.combinedRows.map((row) => {
        return row.filter((item) => {
          if (clearFrames) {
            return false;
          }

          const frame = item as CardFrame;
          if (frame.cards) {
            frame.cards = [];
            return true;
          }
        });
      });
    }
  }

  /** determine the offset of the cursor from
   * the drag preview's top left corner
   */
  getPreviewOffset(event: MouseEvent): void {
    if (!this.preview) {
      this.preview = document.querySelector(Selectors.DragPreview);
    }

    if (this.preview) {
      const [x, y] = this.preview.style.transform
        .replace('translate3d(', '')
        .replace(')', '')
        .replace(/px/g, '')
        .split(', ');

      this.offsetX = event.x - +x + this.spacing;
      this.offsetY = event.y - +y + this.spacing;
    }
  }

  /** maps points from a touch event to a mouse event
   * and passes on to the getPreviewOffset fn
   */
  getTouchPreviewOffset(event: TouchEvent): void {
    // ensures that there's actually something being dragged
    if (this.activeSource) {
      // grab coords from touch point
      const touch = event.touches.item(0);
      const [x, y] = [touch.clientX, touch.clientY];

      // create mouse event and pass it along
      const mouseEvent = new MouseEvent('mousemove');
      this.getPreviewOffset({ ...mouseEvent, x, y });
    }
  }

  /** store the location in which the drag started
   * to make it easier to remove original elements
   */
  setDragSource(
    source: DragSource,
    rowIndex: number = null,
    frameIndex = null
  ) {
    this.activeSource = source;
    if (rowIndex !== null) {
      this.activeRow = rowIndex;
    }

    if (frameIndex !== null) {
      this.activeFrame = frameIndex;
    }
  }

  /** gets the type of the given item */
  private getItemType(item: Card | CardFrame): ItemType {
    return (item as CardFrame).type ? ItemType.frames : ItemType.cards;
  }

  /** clear the offset at the end of a drag */
  private initOffset(): void {
    this.preview = null;
    this.offsetX = 0;
    this.offsetY = 0;
  }

  /** handle when cards & frames are dropped onto the drop rows */
  handleDrop(
    event: CdkDragDrop<Card | CardFrame>,
    row: number,
    container: HTMLDivElement
  ): void {
    const data = { ...event.item.data } as Card | CardFrame;
    const isCard = this.getItemType(data) === ItemType.cards;
    const droppedElement = event.item.element.nativeElement;

    // adjust x positioning
    data.xOffset = event.dropPoint.x - container.offsetLeft - this.offsetX;

    // add the card/frame to the drop row
    if (isCard) {
      // card
      const card = data as Card;

      // adjust y positioning
      const offsetTop =
        container.offsetTop +
        (container.offsetParent as HTMLDivElement).offsetTop;
      card.yOffset = event.dropPoint.y - offsetTop - this.offsetY;

      // determine whether to discard
      const outOfHorizontalBounds =
        card.xOffset < 0 || card.xOffset > container.clientWidth;
      const outOfVerticalBounds =
        card.yOffset < -(container.clientHeight / 2) ||
        card.yOffset > container.clientHeight;
      if (outOfHorizontalBounds || outOfVerticalBounds) {
        this.removeFromSource(event);
        this.initOffset();
        return;
      }

      // check if a frame exists at dropPoint
      const elements = document.elementsFromPoint(
        event.dropPoint.x,
        event.dropPoint.y
      );
      const frameEl = elements.find((el) =>
        el.classList.contains('frame')
      ) as HTMLDivElement;

      if (frameEl) {
        // dropping on a frame
        card.xOffset -= frameEl.offsetLeft;
        const frameIndex = +frameEl.dataset.index;
        const frameCards = this.dropRows[row].frames[frameIndex].cards;
        this.dropRows[row].frames[frameIndex].cards = [...frameCards, card];
      } else {
        this.dropRows[row].cards = [...this.dropRows[row].cards, card];
      }
    } else {
      const frame = data as CardFrame;

      // cap frame positioning
      if (frame.xOffset > container.clientWidth) {
        frame.xOffset = container.clientWidth - droppedElement.clientWidth;
      } else if (frame.xOffset < 0 - droppedElement.clientWidth) {
        frame.xOffset = 0;
      }

      // frame
      this.dropRows[row].frames = [...this.dropRows[row].frames, frame];
    }

    // remove item from source
    this.removeFromSource(event);
    this.initOffset();
  }

  /** use drag source info to remove original dragged items */
  removeFromSource(event: CdkDragDrop<Card | CardFrame>): void {
    const type = this.getItemType(event.item.data);

    /** the element html holds the true previous index
     * (as opposed to event.previousIndex) */
    const oldIndex = +event.item.element.nativeElement.dataset.index;
    this.updateDropRows(
      this.activeSource,
      this.activeRow,
      type,
      oldIndex,
      this.activeFrame
    );

    // clear source
    this.activeSource = null;
    this.activeRow = -1;
    this.activeFrame = -1;
  }

  /** remove the specified item from the drop rows */
  private updateDropRows(
    source: DragSource,
    row: number,
    type: ItemType,
    index: number,
    frameIndex: number = null
  ): void {
    // remove item from source row
    if (source === DragSource.dropRow) {
      if (this.keyboardModeEnabled) {
        this.combinedRows[row].splice(index, 1);
      } else {
        this.dropRows[row][type].splice(index, 1);
      }
    } else if (source === DragSource.frame) {
      // remove item from source frame
      if (this.keyboardModeEnabled) {
        (this.combinedRows[row][frameIndex] as CardFrame).cards.splice(
          index,
          1
        );
      } else {
        this.dropRows[row].frames[frameIndex].cards.splice(index, 1);
      }
    }
  }

  /** handle when cards are dropped onto the letter grid
   * (they shouldn't be, so they get instantly removed)
   */
  removeCard(event: CdkDragDrop<Card | CardFrame>): void {
    const isCard = (event.item.data as Card).filePath !== undefined;

    if (isCard) {
      this.initOffset();
      this.removeFromSource(event);
    }
  }

  /** handle when a frame's close button is clicked */
  closeFrame(frame: CardFrame, rowIndex: number, frameIndex: number): void {
    const addFrameOffset = (card: Card) => {
      card.xOffset += frame.xOffset;
      card.yOffset = undefined;
      return card;
    };
    // add the cards to the row
    this.dropRows[rowIndex].cards = [
      ...this.dropRows[rowIndex].cards,
      ...frame.cards.map(addFrameOffset),
    ];

    // remove the frame from the row
    this.updateDropRows(
      DragSource.dropRow,
      rowIndex,
      ItemType.frames,
      frameIndex
    );
  }

  // ==============================
  // KEYBOARD ACCESSIBILITY
  // ==============================
  /** listens for keydown events and performs
   * the corresponding action, if any
   */
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(
    event: KeyboardEvent,
    item: Card | CardFrame = null,
    source: DragSource = null,
    itemIndex: number = -1,
    rowIndex: number = -1,
    frameIndex: number = -1
  ): void {
    // clear the keyboard sequence and block unhandled event codes
    this.clearKeySequence(event);
    if (
      !this.keyboardModeEnabled ||
      event.stopPropagation() ||
      event.code === 'Tab' ||
      event.ctrlKey ||
      event.repeat
    ) {
      return;
    }

    // pass the keyboard event and data through shortcut list
    const shortcutHandlers = [
      this.checkKeyboardMovement,
      this.checkKeyboardDeletion,
      this.checkKeyboardNavigation,
    ];

    for (let shortcut of shortcutHandlers) {
      const handled = shortcut(
        event,
        item,
        source,
        itemIndex,
        rowIndex,
        frameIndex
      );
      if (handled) {
        return;
      }
    }
  }

  /** removes the indexed keys to reset key cycling
   * after a different key has been pressed
   */
  private clearKeySequence(event: KeyboardEvent): void {
    if (this.keyboardModeEnabled && !event.repeat) {
      const key = this.cardService.getKey(event.key).toLowerCase();
      if (this.keySequence[0] !== key) {
        this.keySequence = [];
      }
    }
  }

  /** sorts the items in the drop rows based on their x offset */
  private sortDropRows(): void {
    const focusedElement = this.getFocusedElement();
    let restoreFocusId = null;
    if (this.dropZone.nativeElement.contains(focusedElement)) {
      restoreFocusId = focusedElement.dataset.id;
    }

    this.combinedRows = this.combinedRows.map((row) => {
      const xOffsetSort = (itemA: Card | CardFrame, itemB: Card | CardFrame) =>
        itemA.xOffset - itemB.xOffset;
      row.sort(xOffsetSort);

      // sort cards within frames
      row.forEach((item) => {
        const frame = item as CardFrame;
        if (frame.cards) {
          frame.cards.sort(xOffsetSort);
        }
      });
      return row;
    });

    // restore focus if it was lost
    if (restoreFocusId) {
      this.changeDetector.detectChanges();
      const elementToFocus = this.dropZone.nativeElement.querySelector(
        `[data-id="${restoreFocusId}"]`
      );
      (elementToFocus as HTMLElement)?.focus();
    }
  }

  /** finds the bounds of any card/frame elements in the container that collide with the given position */
  private findCollision(
    container: HTMLElement,
    position: number,
    bounds: DOMRect,
    activeElementBounds: DOMRect,
    selectors: string[] = [Selectors.Card, Selectors.Frame]
  ): Collision {
    const focusedElement = this.getFocusedElement();
    const elements = Array.from(
      container.querySelectorAll(selectors.join(', '))
    ).filter((el) => el !== focusedElement) as HTMLElement[];
    const collidingElement = elements.find((el) => {
      const elementBounds = el.getBoundingClientRect();
      const adjustedPosition = bounds.left + position;

      const newBounds = {
        left: adjustedPosition,
        right: adjustedPosition + activeElementBounds.width,
      };
      const l1 = newBounds.left,
        r1 = newBounds.right;
      const l2 = elementBounds.left,
        r2 = elementBounds.right;

      const rightOverlap = l2 < r1 && r2 > r1;
      const leftOverlap = l2 < l1 && r2 > l1;
      const covering = l2 <= l1 && r2 >= r1;
      const inside = l2 > l1 && r2 < r1;

      return rightOverlap || leftOverlap || covering || inside;
    });

    return collidingElement
      ? {
          element: collidingElement,
          bounds: collidingElement.getBoundingClientRect(),
        }
      : null;
  }

  /** checks if given offset is overflowing within the given bounds */
  private isOverflowing(
    offset: number,
    bounds: DOMRect,
    elementBounds: DOMRect,
    direction: number
  ): boolean {
    return direction > 0
      ? offset + elementBounds.width > bounds.width
      : offset < 0;
  }

  /** finds the first open position in a container in a given direction */
  private findOpenPosition(
    container: HTMLElement,
    last: boolean,
    rowIndex: number,
    direction: number,
    startOffset
  ): number {
    const getOpenOffset = (position: number, startAtEnd = direction < 0) => {
      const bounds = container.getBoundingClientRect();
      const element = this.getFocusedElement();
      const elementBounds = element.getBoundingClientRect();

      if (!startOffset && startAtEnd) {
        position = bounds.width - elementBounds.width;
      }

      let collision = this.findCollision(
        container,
        position,
        bounds,
        elementBounds
      );

      while (collision) {
        const diff =
          direction > 0
            ? collision.bounds.right - bounds.left
            : collision.bounds.left - elementBounds.width - bounds.left;
        position = diff + direction * this.itemSpacing;

        if (this.isOverflowing(position, bounds, elementBounds, direction)) {
          return;
        }

        collision = this.findCollision(
          container,
          position,
          bounds,
          elementBounds
        );
      }

      return position;
    };

    let offset = startOffset;
    if (!last) {
      // looking for first open spot
      return getOpenOffset(offset);
    }

    // adjust offset to highest one
    if (direction === 1 && rowIndex > -1) {
      this.combinedRows[rowIndex].forEach((item: Card | CardFrame) => {
        offset = Math.max(item.xOffset, offset);
      });
    }

    // looking for next/prev open spot
    return getOpenOffset(offset);
  }

  /** no spots found, don't move */
  private cancelMove() {
    this.message.emit(`Could not move item.`);
  }

  /** returns the HTML element representing the given drop row
   * useful for after changes have been made to the DOM
   */
  private getContainer(rowIndex: number): HTMLElement {
    const rowSelector = `${Selectors.FrameRow}:nth-of-type(${rowIndex + 1})`;
    return this.dropZone.nativeElement.querySelector(rowSelector);
  }

  /** inserts an item into a row manually and applies focus to it */
  private addItemToRow(item: Card | CardFrame, rowIndex: number): void {
    this.combinedRows[rowIndex] = [...this.combinedRows[rowIndex], item];
    this.sortDropRows();
    this.changeDetector.detectChanges();

    // find and focus the item
    const container = this.getContainer(rowIndex);
    const newElement = container.querySelector(`[data-id="${item.id}"]`);
    (newElement as HTMLElement).focus();
  }

  /** adjusts an item's x offset to the given position if available */
  private setItemPosition(
    item: Card | CardFrame,
    position: number = undefined,
    altPosition: number = undefined
  ): boolean {
    if (position !== undefined) {
      item.xOffset = position;
    } else if (altPosition !== undefined) {
      item.xOffset = altPosition;
    } else {
      return false;
    }

    return true;
  }

  /** moves an item between rows */
  private switchRows(
    item: Card | CardFrame,
    targetRow: number,
    container: HTMLElement
  ): boolean {
    const bounds = container.getBoundingClientRect();
    const element = this.getFocusedElement();
    const elementBounds = element.getBoundingClientRect();
    const hasCollision = this.findCollision(
      container,
      item.xOffset,
      bounds,
      elementBounds
    );

    // only update the x offset if there is a collision
    if (hasCollision) {
      const nextOpenPosition = this.findOpenPosition(
        container,
        true,
        targetRow,
        1,
        item.xOffset
      );
      const previousOpenPosition = this.findOpenPosition(
        container,
        true,
        targetRow,
        -1,
        item.xOffset
      );
      const updated = this.setItemPosition(
        item,
        nextOpenPosition,
        previousOpenPosition
      );
      if (!updated) {
        this.cancelMove();
        return false;
      }
    }

    return true;
  }

  /** move an item onto or between the drop rows */
  private moveItemVertically(
    newItem: Card | CardFrame,
    movedFromGrid: boolean,
    rowIndex: number,
    direction: number,
    type: ItemType
  ): boolean {
    const startRow = movedFromGrid ? this.combinedRows.length : rowIndex;
    const targetRow = Math.abs(
      (startRow + direction) % this.combinedRows.length
    );
    const container = this.getContainer(targetRow);
    const firstOpenPosition = this.findOpenPosition(
      container,
      false,
      rowIndex,
      1,
      newItem.xOffset
    );

    if (movedFromGrid) {
      // assign a new id to it so we can track it
      // while on the drop rows
      newItem.id = `${type}-${Date.now()}`;

      // put item in first position
      const updated = this.setItemPosition(newItem, firstOpenPosition);
      if (!updated) {
        this.cancelMove();
        return false;
      }
    } else {
      const isCyclingUp = direction < 0 && rowIndex === 0;
      const isCyclingDown =
        direction > 0 && rowIndex === this.combinedRows.length - 1;
      if (isCyclingUp || isCyclingDown) {
        return false;
      }

      const updated = this.switchRows(newItem, targetRow, container);
      if (!updated) {
        this.cancelMove();
        return false;
      }
    }

    this.addItemToRow(newItem, targetRow);
    return true;
  }

  /** swaps the offsets of the given items */
  private swapOffsets(
    item: Card | CardFrame,
    otherItem: Card | CardFrame,
    itemBounds: DOMRect,
    otherItemBounds: DOMRect,
    direction: number,
    sameType = false
  ) {
    const currentOffset = item.xOffset;
    const otherOffset = otherItem.xOffset;

    if (sameType) {
      item.xOffset = otherOffset;
      otherItem.xOffset = currentOffset;
    } else if (direction > 0) {
      otherItem.xOffset = currentOffset;
      item.xOffset = currentOffset + otherItemBounds.width + this.itemSpacing;
    } else {
      item.xOffset = otherOffset;
      otherItem.xOffset = otherOffset + itemBounds.width + this.itemSpacing;
    }
  }

  /** finds a colliding item within a source array */
  private findCollidingItem(
    source: (Card | CardFrame)[],
    item: Card | CardFrame,
    direction: number
  ): Card | CardFrame {
    return source.find((checkItem) =>
      direction > 0
        ? checkItem.xOffset > item.xOffset
        : checkItem.xOffset < item.xOffset
    );
  }

  private moveWithinFrame(
    item: Card,
    itemIndex: number,
    rowIndex: number,
    frameIndex: number,
    direction: number,
    bounds: DOMRect,
    elementBounds: DOMRect
  ): boolean {
    if (frameIndex > -1) {
      const rowContainer = this.getContainer(rowIndex);
      const outerBounds = rowContainer.getBoundingClientRect();
      const frame = this.combinedRows[rowIndex][frameIndex];

      const outerOffset =
        direction > 0
          ? frame.xOffset + bounds.width + this.itemSpacing
          : frame.xOffset - elementBounds.width - this.itemSpacing;
      if (
        outerOffset < 0 ||
        outerOffset + elementBounds.width > outerBounds.width
      ) {
        return false;
      }

      const newItem = { ...item };
      const outerPosition = this.findOpenPosition(
        rowContainer,
        direction < 0,
        rowIndex,
        direction,
        outerOffset
      );
      const updated = this.setItemPosition(newItem, outerPosition);
      if (!updated) {
        return false;
      }

      this.updateDropRows(
        DragSource.frame,
        rowIndex,
        ItemType.cards,
        itemIndex,
        frameIndex
      );
      this.addItemToRow(newItem, rowIndex);
      return true;
    }

    return false;
  }
  /** moves an item to the end of its container
   * or swaps it with any colliding elements
   */
  private moveToEndOrSwap(
    item: Card | CardFrame,
    itemIndex: number,
    rowIndex: number,
    frameIndex: number,
    direction: number,
    container: HTMLElement,
    elementBounds: DOMRect
  ): void {
    const type = this.getItemType(item);
    const bounds = container.getBoundingClientRect();
    const frame = this.combinedRows[rowIndex][frameIndex] as CardFrame;
    const source = frame?.cards || this.combinedRows[rowIndex];
    const otherItem = this.findCollidingItem(source, item, direction);

    if (otherItem) {
      const otherType = this.getItemType(otherItem);
      const collision = this.findCollision(
        container,
        otherItem.xOffset,
        bounds,
        elementBounds
      );
      this.swapOffsets(
        item,
        otherItem,
        elementBounds,
        collision.bounds,
        direction,
        type === otherType
      );
    } else if (direction < 0 && item.xOffset > 0) {
      item.xOffset = 0;
    } else if (
      direction > 0 &&
      item.xOffset < bounds.width - elementBounds.width
    ) {
      item.xOffset = bounds.width - elementBounds.width;
    } else {
      const updated = this.moveWithinFrame(
        item as Card,
        itemIndex,
        rowIndex,
        frameIndex,
        direction,
        bounds,
        elementBounds
      );
      if (!updated) {
        this.cancelMove();
      }
    }
  }

  /** closes the gap between items when the distance between
   * it and its collision is greater than the item spacing
   */
  private closeItemGap(
    item: Card | CardFrame,
    collision: Collision,
    direction: number,
    elementBounds: DOMRect
  ): boolean {
    if (
      direction > 0 &&
      collision.bounds.left - elementBounds.right > this.itemSpacing
    ) {
      item.xOffset +=
        collision.bounds.left - elementBounds.right - this.itemSpacing;
      return true;
    } else if (
      direction < 0 &&
      elementBounds.left - collision.bounds.right > this.itemSpacing
    ) {
      item.xOffset -=
        elementBounds.left - collision.bounds.right - this.itemSpacing;
      return true;
    }

    return false;
  }

  private swapOrMoveIntoFrame(
    item: Card | CardFrame,
    element: HTMLElement,
    collision: Collision,
    rowIndex: number,
    frameIndex: number,
    direction: number
  ): boolean {
    const getElementType = (el: HTMLElement) =>
      el.dataset.id.includes(ItemType.cards) ? ItemType.cards : ItemType.frames;

    const elementBounds = element.getBoundingClientRect();
    const otherElement = collision.element;
    const currentType = getElementType(element);
    const otherType = getElementType(otherElement);
    const otherIndex = otherElement.dataset.index;
    const otherItem =
      frameIndex > -1
        ? (this.combinedRows[rowIndex][frameIndex] as CardFrame).cards[
            otherIndex
          ]
        : this.combinedRows[rowIndex][otherIndex];

    if (
      currentType === otherType ||
      (currentType === ItemType.frames && otherType === ItemType.cards)
    ) {
      // same type, or current is frame and other is card
      this.swapOffsets(
        item,
        otherItem,
        elementBounds,
        collision.bounds,
        direction,
        currentType === otherType
      );
    } else {
      // card moving into frame
      const newOffset = this.findOpenPosition(
        otherElement,
        direction < 0,
        rowIndex,
        direction,
        0
      );
      if (newOffset !== undefined) {
        item.xOffset = newOffset;
        const frame = otherItem as CardFrame;
        frame.cards = [...frame.cards, item as Card];
        return true;
      } else {
        this.swapOffsets(
          item,
          otherItem,
          elementBounds,
          collision.bounds,
          direction
        );
      }
    }

    return false;
  }

  /** attempts to move an item in the given direction within the specified bounds */
  private moveWithinBounds(
    item: Card | CardFrame,
    element: HTMLElement,
    container: HTMLElement,
    direction: number,
    itemIndex: number,
    rowIndex: number,
    frameIndex: number
  ): boolean {
    const bounds = container.getBoundingClientRect();
    const elementBounds = element.getBoundingClientRect();
    const type = this.getItemType(item);
    let newOffset =
      item.xOffset + direction * (this.movementSize + this.itemSpacing);

    if (this.isOverflowing(newOffset, bounds, elementBounds, direction)) {
      this.moveToEndOrSwap(
        item,
        itemIndex,
        rowIndex,
        frameIndex,
        direction,
        container,
        elementBounds
      );
      return false;
    } else {
      const baseSelectors = [Selectors.Card, Selectors.Frame];
      const frameSelectors = [
        `${Selectors.Card}:not(${Selectors.Frame} ${Selectors.Card})`,
        Selectors.Frame,
      ];
      const selectors =
        type === ItemType.frames ? frameSelectors : baseSelectors;
      const collision = this.findCollision(
        container,
        newOffset,
        bounds,
        elementBounds,
        selectors
      );

      if (!collision) {
        // just move the element
        item.xOffset = newOffset;
      } else {
        // close the gap first
        const gapClosed = this.closeItemGap(
          item,
          collision,
          direction,
          elementBounds
        );
        if (gapClosed) {
          return false;
        }

        // swap or move into frame
        return this.swapOrMoveIntoFrame(
          item,
          element,
          collision,
          rowIndex,
          frameIndex,
          direction
        );
      }

      return false;
    }
  }

  /** handles horizontal item movement within the drop rows */
  private moveItemHorizontally(
    item: Card | CardFrame,
    direction: number,
    itemIndex: number,
    rowIndex: number,
    frameIndex: number
  ): boolean {
    const isInFrame = frameIndex > -1;

    let container = this.getContainer(rowIndex);
    let element = this.getFocusedElement();

    if (isInFrame) {
      const frameList = container.querySelector(Selectors.ItemList);
      const frameSelector = `${Selectors.Frame}:nth-of-type(${frameIndex + 1})`;
      const frameElement = frameList.querySelector(frameSelector);
      const moved = this.moveWithinBounds(
        item,
        element,
        frameElement as HTMLElement,
        direction,
        itemIndex,
        rowIndex,
        frameIndex
      );

      if (!moved) {
        return false;
      }

      // reset changed values
      itemIndex = Math.max(frameIndex - 1, 0);
      frameIndex = -1;
      container = this.getContainer(rowIndex);
      element = this.getFocusedElement();

      if (direction > 0) {
        return true;
      }
    }

    return this.moveWithinBounds(
      item,
      element,
      container,
      direction,
      itemIndex,
      rowIndex,
      frameIndex
    );
  }

  /** handle item movement */
  private moveItem(
    item: Card | CardFrame,
    source: DragSource,
    directionX: number,
    directionY: number,
    itemIndex: number,
    rowIndex: number,
    frameIndex: number
  ): boolean {
    const movedFromGrid = [DragSource.cards, DragSource.frames].includes(
      source
    );
    if (
      (!directionX && !directionY) ||
      (movedFromGrid && (directionY > 0 || directionX))
    ) {
      return false;
    }

    // find item type
    const newItem = { ...item };
    const type = this.getItemType(newItem);

    if (directionY) {
      const frame =
        rowIndex > -1 ? this.combinedRows[rowIndex][frameIndex] : null;
      newItem.xOffset += frame?.xOffset || 0;
      return this.moveItemVertically(
        newItem,
        movedFromGrid,
        rowIndex,
        directionY,
        type
      );
    }

    return this.moveItemHorizontally(
      item,
      directionX,
      itemIndex,
      rowIndex,
      frameIndex
    );
  }

  /** checks and handles item movement using the keyboard */
  private checkKeyboardMovement = (
    event: KeyboardEvent,
    item: Card | CardFrame,
    source: DragSource,
    itemIndex: number,
    rowIndex: number,
    frameIndex: number
  ): boolean => {
    if (!item || event.altKey || event.shiftKey || event.ctrlKey) {
      return false;
    }

    const bind = this.cardService.toBind(event.code);
    const movementKeys = [
      KeyBind.MoveUp,
      KeyBind.MoveDown,
      KeyBind.MoveLeft,
      KeyBind.MoveRight,
    ];
    if (movementKeys.includes(bind)) {
      event.preventDefault();

      const nativeElement = this.getFocusedElement();
      const horizontalIndex = [
        KeyBind.MoveLeft,
        null,
        KeyBind.MoveRight,
      ].indexOf(bind);
      const verticalIndex = [KeyBind.MoveUp, null, KeyBind.MoveDown].indexOf(
        bind
      );
      const directionX = horizontalIndex > -1 ? horizontalIndex - 1 : 0;
      const directionY = verticalIndex > -1 ? verticalIndex - 1 : 0;

      let itemWasTransferred = this.moveItem(
        item,
        source,
        directionX,
        directionY,
        itemIndex,
        rowIndex,
        frameIndex
      );

      if (itemWasTransferred) {
        // remove it from original source (where applicable)
        this.activeSource = source;
        this.activeRow = rowIndex;
        this.activeFrame = frameIndex;
        this.removeFromSource({
          item: {
            data: item,
            element: {
              nativeElement,
            },
          },
        } as CdkDragDrop<Card | CardFrame>);
      }

      // sort the drop row items by xOffset
      this.sortDropRows();

      if (source === DragSource.frame) {
        event.stopPropagation();
      }

      return true;
    }
  };

  /** checks and handles when the users triggers a deletion */
  private checkKeyboardDeletion = (
    event: KeyboardEvent,
    item: Card | CardFrame,
    source: DragSource,
    itemIndex: number,
    rowIndex: number,
    frameIndex: number
  ): boolean => {
    if (!event.altKey || event.ctrlKey) {
      return false;
    }

    const bind = this.cardService.toBind(event.code);
    const deletionKeys = [KeyBind.DeleteItem, KeyBind.ClearItems];
    if (deletionKeys.includes(bind)) {
      if (bind === KeyBind.DeleteItem) {
        this.triggerDelete(item, source, itemIndex, rowIndex, frameIndex);
      } else {
        this.clearDropRows(event.shiftKey);

        const extraMsg = event.shiftKey ? ' and frames' : '';
        this.message.emit(`Cleared all cards${extraMsg}.`);
      }

      if (frameIndex > -1) {
        event.stopPropagation();
      }

      // sort the drop rows
      this.sortDropRows();

      return true;
    }
  };

  /** checks and handles when the user triggers a navigation */
  private checkKeyboardNavigation = (event: KeyboardEvent): boolean => {
    if (event.altKey || event.ctrlKey) {
      return false;
    }

    const bind = this.cardService.toBind(event.code);

    let navigated = false;
    const alphabet = Array.from(Array(26))
      .map((e, i) => i + 65)
      .map((x) => 'Key' + String.fromCharCode(x).toUpperCase());
    const digits = [KeyBind.DropRow1, KeyBind.DropRow2];
    const navigationKeys = [...alphabet, KeyBind.Quote, ...digits];

    if (navigationKeys.includes(event.code)) {
      navigated = true;

      if (digits.includes(bind)) {
        this.focusDropRow(event);
      } else {
        this.focusCardsAndFrames(event);
      }
    }

    return navigated;
  };

  /** triggers a deletion of a card/frame */
  private triggerDelete(
    item: Card | CardFrame,
    source: DragSource,
    itemIndex: number,
    rowIndex: number,
    frameIndex: number
  ) {
    if (!item || [DragSource.cards, DragSource.frames].includes(source)) {
      this.message.emit('Could not delete item.');
      return;
    }

    const type = this.getItemType(item);
    const safeType = type === ItemType.frames ? 'Frame' : 'Card';
    this.updateDropRows(source, rowIndex, type, itemIndex, frameIndex);
    this.message.emit(`${safeType} deleted.`);
  }

  /** attempts to focus on given element
   * and shows error if failed */
  private applyFocus(element: HTMLElement, relatedName: string): void {
    if (element) {
      element.focus();
    } else {
      this.message.emit(`Could not move focus to ${relatedName}`);
    }
  }

  /** moves focus to the specified drop row */
  private focusDropRow(event: KeyboardEvent): void {
    if (!event.shiftKey) {
      return;
    }

    const rowNumber = +this.cardService.getKey(event.code);
    const dropZone = this.dropZone.nativeElement;
    const row = dropZone.querySelector(
      `${Selectors.FrameRow}:nth-of-type(${rowNumber})`
    );

    // focus on first focusable el in row
    const firstFocusableElement: HTMLDivElement = row.querySelector(
      Selectors.Focusable
    );
    this.applyFocus(firstFocusableElement, `drop row ${rowNumber}`);
  }

  /** moves focus to specific cards/frames/drop rows */
  private focusCardsAndFrames(event: KeyboardEvent): void {
    const bind = this.cardService.toBind(event.code);

    // stop everything but step selector as it is handled in parent
    if (!event.shiftKey || bind !== KeyBind.StepSelector) {
      event.stopPropagation();
    }

    if (event.shiftKey) {
      this.shiftFocus(event);
    } else {
      this.focusCard(this.cardService.getKey(event.key).toLowerCase());
    }
  }

  /** parses and handles keyboard shortcuts for
   * different focus triggers
   */
  private shiftFocus(event: KeyboardEvent): void {
    let relatedName: string;
    const zone = this.dragZone.nativeElement;
    const validKeys = 'BCFNP'.split('').map((char) => `Key${char}`);
    let elementToFocus: HTMLDivElement;
    if (validKeys.includes(event.code)) {
      const bind = this.cardService.toBind(event.code);
      switch (bind) {
        case KeyBind.FirstBlankCard:
          relatedName = 'first blank card';
          elementToFocus = zone.querySelector(Selectors.BlankCard);
          break;
        case KeyBind.FirstCard:
          relatedName = 'first card';
          elementToFocus = zone.querySelector(Selectors.Card);
          break;
        case KeyBind.FirstFrame:
          relatedName = 'first frame';
          elementToFocus = zone.querySelector(
            Selectors.Frame + Selectors.Focusable
          );
          break;
        case KeyBind.NextRow:
          relatedName = 'next row';
          elementToFocus = this.findRow().querySelector(Selectors.Focusable);
          break;
        case KeyBind.PrevRow:
          relatedName = 'previous row';
          elementToFocus = this.findRow(-1).querySelector(Selectors.Focusable);
          break;
      }

      this.applyFocus(elementToFocus, relatedName);
    }
  }

  /** gets a list of cards whose text contain the given key */
  private getCardElements(key: string): HTMLElement[] {
    if (!this.cardElements) {
      this.cardElements = Array.from(
        this.dragZone.nativeElement.querySelectorAll(Selectors.Card)
      );
    }

    /** filters the card elements to only the ones
     * containing the given search term/key */
    const filterCards = (search: string | string[]) => {
      const checkKey = (card: HTMLElement, char: string) => {
        return card.innerText.toLowerCase().startsWith(char);
      };

      return this.cardElements.filter((cardElement: HTMLElement) => {
        if (Array.isArray(search)) {
          return search.some((searchKey) => checkKey(cardElement, searchKey));
        } else {
          return checkKey(cardElement, search);
        }
      });
    };

    const valueToFilter = key === 'e' ? ['e', 'ǝ'] : key;
    return filterCards(valueToFilter);
  }

  /** cycles focus between cards containing the pressed key */
  private focusCard(key: string): void {
    // get filtered list of cards and account for
    // currently focused if it is within the list
    const cards = this.getCardElements(key);
    const startIndex = cards.indexOf(this.getFocusedElement());
    if (!this.keySequence.length) {
      this.keySequence = (key + ',')
        .repeat(startIndex + 1)
        .split(',')
        .filter(Boolean);
    }

    // focus on the card
    const cardToFocus = cards[this.keySequence.length % cards.length];
    this.applyFocus(cardToFocus, `"${key}" card`);

    // add key to our key sequence to allow
    // focus to progress on next call
    this.keySequence = [...this.keySequence, key];
  }

  /** finds the next focusable row in the given direction */
  private findRow(direction = 1): HTMLDivElement {
    const zone = this.dragZone.nativeElement;
    let index = null;
    let rows = Array.from(zone.querySelectorAll(Selectors.CardRow)).filter(
      (row) => row.querySelector(Selectors.Card)
    );

    const focusedElement = this.getFocusedElement();
    if (
      focusedElement &&
      focusedElement.classList.contains(Selectors.Card.slice(1))
    ) {
      // check if in dragzone
      if (zone.contains(focusedElement)) {
        let row = focusedElement.parentElement.parentElement as HTMLDivElement;
        index = rows.indexOf(row);
      }
    }

    const rowIndex = index !== null ? index + direction : 0;
    const mod = rowIndex % rows.length;
    const newIndex = mod > -1 ? mod : rows.length + mod;

    return rows[newIndex] as HTMLDivElement;
  }

  getFocusedElement() {
    return document.activeElement as HTMLElement;
  }
}

export interface Collision {
  element: HTMLElement;
  bounds: DOMRect;
}

export interface CardFrameGroup {
  type: CardFrameType;
  frames: CardFrame[];
}

export interface FrameSection {
  title?: string;
  groups: CardFrameGroup[];
}

export interface DropRow {
  cards: Card[];
  frames: CardFrame[];
}

export type CombinedRow = (Card | CardFrame)[];

export enum ItemType {
  cards = 'cards',
  frames = 'frames',
}

export enum DragSource {
  cards = 'cards',
  frames = 'frames',
  frame = 'frame',
  dropRow = 'dropRow',
}
