import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, SpinnerService } from '@wilson/wilsonng';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Wilson } from 'src/def/Wilson';
import { InitialLoadService } from './initial-load.service';
import Resource = Wilson.Resource;
import ResourceType = Wilson.ResourceType;
import ResourceReport = Wilson.ResourceReport;
import BaseResource = Wilson.BaseResource;
import ProgramResource = Wilson.ProgramResource;
import WidgetResource = Wilson.WidgetResource;
import Search = Wilson.Search;
import Audit = Wilson.Audit;
import Directory = Wilson.Directory;
import { FunnelService } from './funnel.service';
import { UrlSegment } from '@angular/router';
import ResourceResult = Wilson.ResourceResult;
import CollectionResult = Wilson.CollectionResult;
import CollectionResource = Wilson.CollectionResource;

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private controller = 'admin';
  private previewResourceSubject = new BehaviorSubject<Resource>(null);
  previewResource = this.previewResourceSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private initialLoadService: InitialLoadService,
    private funnelService: FunnelService
  ) {
    /* istanbul ignore if */
    if (window['parent']['Cypress']) window['AdminService'] = this;
  }

  setPreviewResource(resource: Resource): void {
    this.previewResourceSubject.next(
      resource ? { ...resource, requiredSubscription: null } : null
    );
  }

  getLandingPageResources(): Observable<Resource[]> {
    return this.apiService.get<Resource[]>(
      `${this.controller}/GetLandingPageResources`
    );
  }

  getResource<T = BaseResource>(resourceId: string): Observable<T> {
    return this.apiService.get<T>(
      `${this.controller}/GetResource/${resourceId}`
    );
  }

  getHubLandingResource<T = BaseResource>(): Observable<T> {
    return this.apiService.get<T>(`${this.controller}/GetHubLandingResource`);
  }

  getResources<T = Resource>(searchModel: Search): Observable<Directory<T>> {
    return this.apiService.post<Directory<T>>(
      `${this.controller}/GetResources`,
      searchModel
    );
  }

  getAudits(
    resourceId: string,
    skip: number,
    take: number
  ): Observable<Directory<Audit>> {
    const params = this.makeParams(skip, take);
    return this.apiService.get<Directory<Audit>>(
      `${this.controller}/GetAudits/${resourceId}?${params}`
    );
  }

  getResourceReport(forceRefresh: boolean): Observable<ResourceReport> {
    return this.apiService.get<ResourceReport>(
      `${this.controller}/Reporting/Dashboard?forceRefresh=${forceRefresh}`
    );
  }

  private makeParams(skip: number, take: number): URLSearchParams {
    return new URLSearchParams({
      skip: skip.toString(),
      take: take.toString(),
    });
  }

  private showUpsertFeedback(
    resultsFeedback: ResultsFeedback,
    resourceId: string
  ): void {
    const isCreation = resultsFeedback.id !== resourceId;
    if (resultsFeedback.isValid) {
      this.messageService.add({
        summary: isCreation ? 'Created' : 'Updated',
        detail: `'${resultsFeedback['name']}' was ${
          isCreation ? 'created' : 'updated'
        }.`,
        closable: false,
      });
    } else {
      //only show the first error
      const key = Object.keys(resultsFeedback.validationResults)[0];
      this.messageService.add({
        summary: 'Invalid Form',
        detail: `${resultsFeedback.validationResults[key]}`,
        closable: false,
      });
    }
  }

  updateVideoPosterFrame(
    resource: Resource,
    selectedPosterFrame: string
  ): Observable<Resource> {
    const formData = new FormData();
    formData.append('resource', JSON.stringify(resource));
    formData.append('selectedPosterFrame', selectedPosterFrame);
    const resultsFeedback: ResultsFeedback = {
      id: resource.id,
      name: resource.name,
      validationResults: null,
      isValid: true,
    };
    return this.apiService
      .put<Resource>(`${this.controller}/UpdateVideoPosterFrame`, formData)
      .pipe(tap(() => this.showUpsertFeedback(resultsFeedback, resource.id)));
  }

  upsertResource(
    resource: Resource,
    thumbnail: File = null,
    files: File[] = null
  ): Observable<ResourceResult> {
    const formData = new FormData();
    if (thumbnail) {
      formData.append('thumbnailFile', thumbnail, thumbnail.name);
    }
    if (files?.length) {
      files.forEach((file) => formData.append('files', file));
    }

    formData.append('resource', JSON.stringify(resource));

    return this.apiService
      .put<ResourceResult>(`${this.controller}/UpsertResource`, formData)
      .pipe(
        tap((result: ResourceResult) => {
          this.getFeedback(result, resource.id);
        })
      );
  }

  upsertCollectionResource(
    resource: CollectionResource,
    thumbnail: File = null
  ): Observable<CollectionResult> {
    const formData = new FormData();
    if (thumbnail) {
      formData.append('thumbnailFile', thumbnail, thumbnail.name);
    }
    formData.append('resource', JSON.stringify(resource));
    return this.apiService
      .put<CollectionResult>(
        `${this.controller}/UpsertCollectionResource`,
        formData
      )
      .pipe(
        tap((result: CollectionResult) => {
          this.getFeedback(result, resource.id);
        })
      );
  }

  updateProgramReferencedCollection(resource: CollectionResource) {
    const formData = new FormData();
    formData.append('resource', JSON.stringify(resource));
    return this.apiService
      .put<CollectionResult>(
        `${this.controller}/UpdateProgramReferencedCollection`,
        formData
      )
      .pipe(
        tap((result: CollectionResult) => {
          this.getFeedback(result, resource.id);
        })
      );
  }

  updateResourceProperty(
    resourceId: string,
    property: string,
    value: unknown
  ): Observable<void> {
    return this.apiService.patch(
      `${this.controller}/UpdateResourceProperty/${resourceId}/${property}`,
      value,
      new HttpHeaders({ 'content-type': 'application/json' })
    );
  }

  updateProgramProperties(
    updates: ProgramResource
  ): Observable<ProgramResource[]> {
    return this.apiService
      .patch<void>(`${this.controller}/UpdateProgramProperties`, updates)
      .pipe(
        switchMap(() => this.funnelService.loadFunnels()),
        tap(() => {
          this.messageService.add({
            summary: 'Updated',
            detail: `The program was updated successfully.`,
            closable: false,
          });
        })
      );
  }

  insertProgramWidgetChild(widget: WidgetResource): Observable<void> {
    return this.apiService
      .patch<void>(
        `${this.controller}/InsertProgramWidgetChild/${widget.subType}`
      )
      .pipe(
        tap(() => {
          this.messageService.add({
            summary: 'Added',
            detail: `The '${widget.name}' widget was added successfully.`,
            closable: false,
          });
        })
      );
  }

  removeProgramWidgetChild(widget: WidgetResource): Observable<void> {
    return this.apiService
      .patch<void>(
        `${this.controller}/RemoveProgramWidgetChild/${widget.subType}`
      )
      .pipe(
        tap(() => {
          this.messageService.add({
            summary: 'Removed',
            detail: `The '${widget.name}' widget was removed successfully.`,
            closable: false,
          });
        })
      );
  }

  insertCollectionChild(
    collectionId: string,
    resourceId: string,
    resourceType: ResourceType
  ): Observable<void> {
    return this.apiService.patch<void>(
      `${this.controller}/InsertCollectionChild/${collectionId}/${resourceType}/${resourceId}`
    );
  }

  reorderCollection(
    collectionId: string,
    resourceIds: string[]
  ): Observable<void> {
    return this.apiService.patch(
      `${this.controller}/ReorderCollection/${collectionId}`,
      resourceIds
    );
  }

  removeCollectionChild(
    collectionId: string,
    resource: BaseResource
  ): Observable<void> {
    return this.apiService
      .patch<void>(
        `${this.controller}/RemoveCollectionChild/${collectionId}/${resource.id}`
      )
      .pipe(
        tap(() => {
          this.messageService.add({
            summary: 'Removed',
            detail: `'${resource.name}' was removed from the collection.`,
            closable: false,
          });
        })
      );
  }

  deleteResource(resource: BaseResource): Observable<void> {
    // this operation can be slow, so build in a spinner
    this.spinnerService.show();
    return this.apiService
      .delete<void>(`${this.controller}/DeleteResource/${resource.id}`)
      .pipe(
        tap(() => {
          this.spinnerService.hide();
          this.messageService.add({
            summary: 'Deleted',
            detail: `'${resource.name}' was deleted.`,
            closable: false,
          });
        })
      );
  }

  reindexAlgoliaCache(): Observable<void> {
    return this.apiService.patch<void>(
      `${this.controller}/RecreateAlgoliaIndex`
    );
  }

  refreshSitemapCache(): Observable<void> {
    return this.apiService.patch<void>(
      `${this.controller}/RefreshSitemapCache`
    );
  }

  getBannersLocation(): string {
    const {
      imageLocations: { Banners },
    } = this.initialLoadService.initialLoad;
    return Banners;
  }

  getAudit(id: string): Observable<Audit> {
    return this.apiService.get<Audit>(`${this.controller}/GetAudit/${id}`);
  }

  getAdminFilters(funnelId: string, pageUrl: UrlSegment[]): any {
    if (!funnelId || !pageUrl?.length) {
      return {};
    }

    const filters = JSON.parse(localStorage.getItem('adminFilters') || '{}');
    const lastSegment = pageUrl[pageUrl.length - 1].path;
    // Only return the relevant filters for the given funnel + page
    return filters[funnelId]?.[lastSegment] ?? {};
  }

  setAdminFilters(filters: any, funnelId: string, pageUrl: UrlSegment[]): void {
    if (!filters || !funnelId || !pageUrl?.length) {
      return;
    }

    let cachedFilters = JSON.parse(localStorage.getItem('adminFilters'));

    // First time caching any filters
    if (!cachedFilters) {
      cachedFilters = {};
    }

    // First time caching filters for this program
    if (!cachedFilters[funnelId]) {
      cachedFilters[funnelId] = {};
    }

    const lastSegment = pageUrl[pageUrl.length - 1].path;
    cachedFilters[funnelId][lastSegment] = filters;
    localStorage.setItem('adminFilters', JSON.stringify(cachedFilters));
  }

  private getFeedback(
    result: CollectionResult | ResourceResult,
    id: string
  ): void {
    const resultsFeedback: ResultsFeedback = {
      id: result.upsertResource.id,
      name: result.upsertResource.name,
      isValid: result.isValid,
      validationResults: result.validationResults,
    };
    this.showUpsertFeedback(resultsFeedback, id);
  }
}

export interface FillSuggestionsEvent {
  query: string;
  originalEvent: InputEvent;
}

export interface FileEvent {
  currentFiles: File[];
  files: File[];
  originalEvent: Event;
}

interface ResultsFeedback {
  id: string;
  name: string;
  isValid: boolean;
  validationResults: { [key: string]: string };
}
