<ng-container [ngSwitch]="data.subType">
  <!-- Academy Widget -->
  <app-academy-widget
    *ngSwitchCase="widgetType.academyStatistics"
    [data]="data"
    [flagText]="flagText"
    [flagClass]="flagClass"
    [styleClass]="styleClass + ' widget'"
  >
    <ng-content></ng-content>

    <div tile-header>
      <ng-content select="[tile-header]"></ng-content>
    </div>
  </app-academy-widget>

  <!-- WRS Card Activity Widget -->
  <app-wrs-card-activity-widget
    *ngSwitchCase="widgetType.wrsCardActivity"
    [data]="data"
    [flagText]="flagText"
    [flagClass]="flagClass"
    [styleClass]="styleClass + ' widget'"
  >
    <ng-content></ng-content>

    <div tile-header>
      <ng-content select="[tile-header]"></ng-content>
    </div>
  </app-wrs-card-activity-widget>

  <!--Hub Choose Unit Widget-->
  <app-choose-unit
    *ngSwitchCase="widgetType.funHubChooser"
    [data]="data"
    [flagText]="flagText"
    [flagClass]="flagClass"
    [styleClass]="styleClass + ' widget'"
  >
    <ng-content></ng-content>

    <div tile-header>
      <ng-content select="[tile-header]"></ng-content>
    </div>
  </app-choose-unit>
</ng-container>
