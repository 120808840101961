import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, GoogleAnalyticsService } from '@wilson/wilsonng';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserService } from './user.service';
import mixpanel from 'mixpanel-browser';
import { Wilson } from 'src/def/Wilson';
import Resource = Wilson.Resource;
import UserPinContainer = Wilson.UserPinContainer;

@Injectable({
  providedIn: 'root',
})
export class PinService {
  private controller = 'pin';
  private activeResourceDataSubject = new BehaviorSubject<ActiveResourceData>({
    lastPinnedResourceId: null,
    currentResource: null,
  });
  activeResourceData = this.activeResourceDataSubject.asObservable();
  private resourceToFilterSubject = new BehaviorSubject<string>(null);
  resourceToFilter = this.resourceToFilterSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private userService: UserService
  ) {}

  private makeParams(values: Record<string, primitive>): URLSearchParams {
    let stringValues = {};
    for (const key of Object.keys(values)) {
      stringValues = { ...stringValues, [key]: values[key].toString() };
    }

    return new URLSearchParams(stringValues);
  }

  pinResource(resource: Resource, containerId: string): Observable<void> {
    mixpanel.track('Resource Pinned', {
      'Resource Name': resource.name,
      'Resource Type': resource.type,
      'Resource ID': resource.id,
    });
    this.removeActiveResource(resource.id);
    return this.apiService
      .put<void>(`${this.controller}/PinResource/${containerId}/${resource.id}`)
      .pipe(
        tap(() => {
          this.userService.user.resourcesPinned = [
            ...(this.userService.user.resourcesPinned || []),
            resource.id,
          ];
        })
      );
  }

  unpinResource(resource: Resource): Observable<void> {
    mixpanel.track('Resource UnPinned', {
      'Resource Name': resource.name,
      'Resource Type': resource.type,
      'Resource ID': resource.id,
    });
    return this.apiService
      .delete<void>(`${this.controller}/UnpinResource/${resource.id}`)
      .pipe(
        tap(() => {
          this.userService.user.resourcesPinned =
            this.userService.user.resourcesPinned?.filter(
              (x) => x !== resource.id
            );
        })
      );
  }

  setActiveResource(currentResource: Resource): void {
    this.activeResourceDataSubject.next({
      lastPinnedResourceId: null,
      currentResource,
    });
  }

  removeActiveResource(lastPinnedResourceId: string = null): void {
    this.activeResourceDataSubject.next({
      lastPinnedResourceId,
      currentResource: null,
    });
  }

  setResourceToFilter(resourceId: string = null): void {
    this.resourceToFilterSubject.next(resourceId);
  }

  createContainer(containerName: string): Observable<UserPinContainer> {
    return this.apiService.put<UserPinContainer>(
      `${this.controller}/CreateContainer`,
      `'${containerName}'`,
      new HttpHeaders({ 'content-type': 'application/json' })
    );
  }

  deleteContainer(containerId: string): Observable<void> {
    return this.apiService.delete<void>(
      `${this.controller}/DeleteContainer/${containerId}`
    );
  }

  renameContainer(
    containerId: string,
    containerName: string
  ): Observable<void> {
    return this.apiService.patch<void>(
      `${this.controller}/RenameContainer/${containerId}`,
      `'${containerName}'`,
      new HttpHeaders({ 'content-type': 'application/json' })
    );
  }

  setContainerAsDefault(containerId: string): Observable<void> {
    return this.apiService.patch<void>(
      `${this.controller}/SetContainerAsDefault/${containerId}`
    );
  }

  moveResourceToContainer(
    resourceId: string,
    newContainerId: string
  ): Observable<void> {
    return this.apiService.patch<void>(
      `${this.controller}/MoveResourceToContainer/${resourceId}/${newContainerId}`
    );
  }

  getPinContainerPage(
    containerId: string,
    skip: number,
    take: number
  ): Observable<UserPinContainer> {
    const params = this.makeParams({ skip, take });
    return this.apiService.get<UserPinContainer>(
      `${this.controller}/GetPinContainerPage/${containerId}?${params}`
    );
  }

  getPinContainers(skip: number, take: number): Observable<UserPinContainer[]> {
    const params = this.makeParams({ skip, take });
    return this.apiService.get<UserPinContainer[]>(
      `${this.controller}/GetPinContainers?${params}`
    );
  }
}

type primitive = string | number | boolean;

export interface PinStatusChangeEvent {
  status: boolean;
  resource: Resource;
}

export interface ActiveResourceData {
  lastPinnedResourceId: string;
  currentResource: Resource;
}
