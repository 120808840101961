import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Wilson } from 'src/def/Wilson';
import InitialLoad = Wilson.InitialLoad;

@Injectable({
  providedIn: 'root',
})
export class InitialLoadService {
  initialLoad: InitialLoad;
  constructor(private httpClient: HttpClient, private handler: HttpBackend) {
    // Workaround to bypass the AuthHttpInterceptor. This should only be used for the getInitialLoad call.
    // Any other HTTP calls should use the ApiService
    this.httpClient = new HttpClient(this.handler);
  }

  getInitialLoad(): Promise<InitialLoad> {
    return this.httpClient
      .get('InitialLoad/GetInitialLoad')
      .pipe(
        tap((load: InitialLoad) => {
          this.initialLoad = load;
        })
      )
      .toPromise();
  }
}
export interface ImageLocations {
  BaseUrl: string;
  Banners: string;
  Icons: string;
}
