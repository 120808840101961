import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalLinkMassagerPipe } from './external-link-massager.pipe';

@NgModule({
  declarations: [ExternalLinkMassagerPipe],
  providers: [ExternalLinkMassagerPipe],
  imports: [CommonModule],
  exports: [ExternalLinkMassagerPipe],
})
export class ExternalLinkMassagerPipeModule {}
