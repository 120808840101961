export namespace Wilson {
  export enum Roles {
    prevention_Learning_Community = '00',
    intervention_Learning_Community = '01',
    intensive_Learning_Community = '02',
    fundations_Pre_K_Community = '13',
    ready_To_Rise = '69',
    web_Admin = '04',
    instructor = '05',
    administrator_Portal = '09',
    control_Panel_User = '16',
    content_Lead = '60',
    tech_Dev = '61',
    allow_Impersonate = '17',
    prevention_Virtual_Coaching = '14',
    intervention_Virtual_Coaching = '15',
    wordalyzer_Access = '11',
    wordalyzer_ContentApprover = '55',
    wordalyzer_ContentEditor = '56',
    subscription_Admin = '71',
    subscription_Customer_Portal = '72',
    subscription_Fundations_AllLevels = '81',
    subscription_Fundations_K = '73',
    subscription_Fundations_1 = '75',
    subscription_Fundations_2 = '77',
    subscription_Fundations_3 = '79',
    subscription_Interactivities_K = '74',
    subscription_Interactivities_1 = '76',
    subscription_Interactivities_2 = '78',
    subscription_Interactivities_3 = '80',
    subscription_Fundations_TeachersManual_K = '82',
    subscription_Fundations_TeachersManual_1 = '83',
    subscription_Fundations_TeachersManual_2 = '84',
    subscription_Fundations_TeachersManual_3 = '85',
    subscription_Fundations_AllLevels_WithPractice = '86',
  }

  export interface RequiredSubscription {
    subscription: string;
    signUpLink: string;
    description: string;
    name: string;
    hideIfUnsubscribed: boolean;
  }

  export interface FlatTag extends BaseEntity {
    name: string;
    resourceTypes: string[];
    programIds: string[];
  }

  export interface Notification {
    text: string;
    override: NotificationFlag;
    show: boolean;
  }

  export interface NotificationFlag {
    isPublished: boolean;
    publishExpirationDate: Date | null;
    text: string;
  }

  export interface BreadCrumb {
    id: string;
    name: string;
    type: string;
    subType: string;
  }

  export interface BaseEntity extends DatabaseEntity<string> {}

  export enum ProgramType {
    community = 'community',
    hub = 'hub',
  }

  export interface DatabaseEntity<TId> {
    id: TId;
  }


  export interface InitialLoad {
    isProduction: boolean;
    isDownForMaintenance: boolean;
    isComingSoon: boolean;
    currentBuildNumber: string;
    imageLocations: { [key: string]: string };
    tokens: { [key: string]: string };
    paths: { [key: string]: string };
  }

  export interface Search {
    types: string[];
    tags: string[];
    parentProgramIds: string[];
    skip: number;
    take: number;
    sortProperty: string;
    sortDirection: number;
    filter: string;
  }

  export interface Audit extends BaseEntity {
    itemId: string;
    itemType: string;
    auditType: string;
    loggedAt: Date;
    loggedById: string;
    loggedByName: string;
    previousEntity: BaseEntity;
    reasonForChange: string;
  }

  export enum WidgetType {
    academyStatistics = 'academyStatistics',
    wrsCardActivity = 'wrsCardActivity',
    funHubChooser = 'funHubChooser',
  }

  export interface Directory<T> {
    items: T[];
    count: number;
  }

  export interface User extends BaseEntity {
    wilsonId: string;
    authId: string;
    name: string;
    email: string;
    lastLogin: Date;
    initials: string;
    lastViewedProgramId: string;
    programs: UserProgram[];
    roles: Roles[];
    resourcesPinned: string[];
    isAdmin: boolean;
    isTechDev: boolean;
  }

  export interface UserPinContainer {
    id: string;
    name: string;
    isDefaultFolder: boolean;
    pinnedResourceCount: number;
    pinnedResources: BaseResource[];
  }

  export interface UserProgram {
    id: string;
    lastLogin: Date;
    lastViewedAnnouncements: Date;
    lastViewedExpertTips: Date;
    lastViewedTutorialPopup: Date;
    lastAcceptedTermsConditions: Date;
    pinContainers: UserPinContainer[];
  }

  export enum ResourceType {
    announcement = 'announcement',
    collection = 'collection',
    externalLink = 'externalLink',
    expertTip = 'expertTip',
    pdf = 'pdf',
    program = 'program',
    soundCards = 'soundCards',
    cursiveCards = 'cursiveCards',
    text = 'text',
    terms = 'terms',
    video = 'video',
    widget = 'widget',
    file = 'file',
    iframe = 'iframe',
    faq = 'faq',
  }

  export interface HubUnitsDefinition {}

  export interface HubProgramUnits {
    programKey: string;
    units: HubUnitWeeks[];
  }

  export interface HubUnitWeeks {
    key: number;
    title: string;
    weeks: HubWeek[];
  }

  export interface HubWeek {
    key: number;
    title: string;
  }

  export interface AnnouncementResource extends Resource {
    postDate: Date;
    bannerExpirationDate: Date | null;
    bannerMessage: string;
  }

  export interface BaseResource extends BaseEntity {
    type: ResourceType;
    name: string;
    description: string;
    descriptionFormatted: string;
    imageUrl: string;
    callToActionText: string;
    isPublished: boolean;
    requiredSubscription: RequiredSubscription;
    isDeletable: boolean;
    createdDate: Date;
    createdBy: string;
    lastModifiedDate: Date;
    lastModifiedBy: string;
    notification: Notification;
    isSearchable: boolean;
    tags: FlatTag[];
  }

  export interface BaseResult {
    isValid: boolean;
    validationResults: { [key: string]: string };
  }

  export interface CollectionResource extends BaseResource {
    fullDescription: string;
    parentProgramId: string;
    childResources: BaseResource[];
    childResourceCount: number;
    childResourceType: string;
    isDescriptionAllowed: boolean;
  }

  export interface CollectionResult extends BaseResult {
    upsertResource: CollectionResource;
  }

  export interface CursiveCardsResource extends Resource {}

  export interface ExpertTipResource extends Resource {
    postDate: Date | null;
  }

  export interface ExternalLinkResource extends Resource {
    url: string;
    openInNewWindow: boolean;
    isSecureDocument: boolean;
  }

  export interface FaqResource extends TextResource {}

  export interface FileResource extends Resource {
    url: string;
    fileName: string;
  }

  export interface HubVideoResource extends VideoResource {
    originalId: string;
    programTrees: ProgramTree[];
  }
  export interface ProgramTree {
    unit: string;
    week: string;
    categoryId: string;
    categoryName: string;
  }

  export interface IframeResource extends Resource {
    url: string;
  }

  export interface PdfResource extends Resource {
    url: string;
    fileName: string;
    thumbnailAutogenerated: boolean;
  }

  export interface ProgramResource extends CollectionResource {
    subType: ProgramType;
    iconImageUrl: string;
    themeId: string;
    discussionBoardUrl: string;
    revisionsAndSupplementsId: string;
    faqId: string;
    termsConditionsId: string;
    expertTipsId: string;
    gettingStartedId: string;
    gettingStartedVisible: boolean;
    revisionsAndSupplementsVisible: boolean;
    faqVisible: boolean;
    termsConditionsVisible: boolean;
    announcementVisible: boolean;
    isSharingAllowed: boolean;
  }

  export interface ReferenceResource {
    id: string;
    type: string;
    name: string;
    programId: string;
    programFriendlyName: string;
  }

  export interface Resource extends BaseResource {
    parentProgramIds: string[];
    relatedResources: Resource[];
    attachedResources: Resource[];
    isPinned: boolean;
    viewCount: number;
    pinCount: number;
    isSharingPermitted: boolean;
    referenceResourcesDetailed: ReferenceResource[];
  }

  export interface ResourceResult extends BaseResult {
    upsertResource: Resource;
  }

  export interface SoundCardsResource extends Resource {}

  export interface TermsResource extends Resource {
    checkboxText: string;
    showAfter: Date;
  }

  export interface TextResource extends Resource {}

  export interface VideoResource extends Resource {
    url: string;
    backupUrl: string;
    thumbnailAutogenerated: boolean;
    availablePosterFrames: string[];
  }

  export interface ProgramTree {
    unit: string;
    week: string;
    categoryId: string;
    categoryName: string;
  }

  export interface BaseReport extends BaseEntity {}

  export interface ResourceReport extends BaseReport {
    lastUpdated: Date;
    programId: string;
    latestUpserted: BaseResource[];
    mostPinned: Resource[];
    mostViewed: Resource[];
    totalCount: number;
    totalUsers: number;
    totalSearches: number;
    searchNoResultRate: number;
    totalCountByType: { [key: string]: number };
    totalLogins: { [key: string]: number };
    topSearches: TopSearch[];
    topSearchesWithNoResults: TopSearch[];
    topTypesSearched: { [key: string]: number };
    topTagsSearched: { [key: string]: number };
  }

  export interface TopSearch {
    query: string;
    count: number;
  }

  export interface Sharelink extends BaseEntity {
    programId: string;
    resourceIds: string[];
    generatedOn: Date;
    expiresOn: Date;
    isActive: boolean;
    createdByUserId: string;
    createdByUserName: string;
  }

  export interface WidgetFunHubChooser extends WidgetResource {}

  export interface WidgetMostRecentExpertTip extends WidgetResource {
    subcollectionId: string;
    expertTipId: string;
  }

  export interface WidgetResource extends Resource {
    subType: WidgetType;
  }

  export interface WidgetWrsCardActivity extends WidgetResource {
    activityUrl: string;
  }

  export interface WidgetEnrollmentResource extends WidgetResource {
    activeTrainingCount: number;
    activeAssignmentsCount: number;
    trainings: WidgetTraining[];
    retrievedOn: Date;
  }

  export interface WidgetTraining {
    name: string;
    trainingUrl: string;
  }

  export interface SiteMap extends BaseEntity {
    map: { [key: string]: SiteMapNode };
  }

  export interface SiteMapNode {
    name: string;
    link: string;
    children: SiteMapNode[];
    expanded: boolean;
    styleClass: string;
  }

  export interface Tag extends BaseEntity {
    name: string;
    description: string;
    resourceTypes: string[];
    taggedItemsCount: number;
  }

  export interface TagContainer extends BaseEntity {
    name: string;
    description: string;
    programIds: string[];
    tags: Tag[];
  }

}
