import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ActiveResourceData,
  PinService,
  PinStatusChangeEvent,
} from 'src/app/services/pin.service';
import { Wilson } from 'src/def/Wilson';
import Resource = Wilson.Resource;

@Component({
  selector: 'app-pin-button',
  templateUrl: './pin-button.component.html',
  styleUrls: [],
})
export class PinButtonComponent implements OnInit {
  @Input() resource: Resource;
  @Input() styleClass = '';
  @Output()
  pinStatusChanged = new EventEmitter<PinStatusChangeEvent>();
  @ContentChild(TemplateRef) template: TemplateRef<HTMLElement>;

  constructor(
    private pinService: PinService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.watchActiveResource().subscribe();
  }

  watchActiveResource(): Observable<ActiveResourceData> {
    return this.pinService.activeResourceData.pipe(
      tap((data) => {
        if (
          !data.currentResource &&
          data.lastPinnedResourceId === this.resource.id
        ) {
          this.emitStatus(true);
        }
      })
    );
  }

  togglePin(event: Event): void {
    event.stopPropagation();

    if (this.resource.isPinned) {
      this.confirmationService.confirm({
        message: `Are you sure you want to unpin '${this.resource.name}'?`,
        header: 'Unpin Resource',
        icon: 'fas fa-times-circle',
        defaultFocus: 'close',
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => this.unpinResource().subscribe(),
      });
    } else {
      this.pinResource();
    }
  }

  pinResource(): void {
    // open the dialog
    this.pinService.setActiveResource(this.resource);
  }

  unpinResource(): Observable<void> {
    this.emitStatus(false);
    return this.pinService.unpinResource(this.resource).pipe(
      tap(() => {
        // notification
        this.messageService.add({
          summary: 'Unpinned',
          detail: `'${this.resource.name}' was successfully unpinned.`,
          closable: false,
        });
      })
    );
  }

  emitStatus(status: boolean): void {
    // set the resource pin status locally
    this.resource.isPinned = status;

    this.pinStatusChanged.emit({
      status,
      resource: this.resource,
    });
  }
}
