import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CardService, Card, CardUnit } from 'src/app/services/card.service';
import { FunnelService } from 'src/app/services/funnel.service';

@Component({
  selector: 'app-sound-card-activity',
  templateUrl: './sound-card-activity.component.html',
  styleUrls: ['./sound-card-activity.component.scss'],
})
export class SoundCardActivityComponent implements OnInit {
  data: SoundCardData;
  currentLevel: CardUnit[];
  selectedUnit: CardUnit;
  cardRows: Card[][];
  activeCard: Card;
  activeAudio: HTMLAudioElement;

  // if the current funnel does not exist, show a picker
  @Input() forceFunnelSelector = false;
  showFunnelSelector = false;
  funnelOptions: string[];
  selectedFunnel: string;

  constructor(
    private apiService: ApiService,
    private funnelService: FunnelService,
    private cardService: CardService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getSoundCards().subscribe();
  }

  getSoundCards(): Observable<SoundCardData> {
    return this.apiService
      .getLocalAsset<SoundCardData>(`/assets/data/sound-cards.json`)
      .pipe(
        tap((data) => {
          this.data = data;
          this.funnelOptions = Object.keys(data);

          const funnelId = this.funnelService.getCurrentFunnel().id;
          if (this.data[funnelId]) {
            this.showFunnelSelector = this.forceFunnelSelector;
            this.selectedFunnel = funnelId;
          } else {
            this.showFunnelSelector = true;
            this.selectedFunnel = this.funnelOptions[0];
          }

          this.currentLevel = this.data[this.selectedFunnel];

          this.checkQueryParams();
        })
      );
  }

  checkQueryParams(): void {
    const unitIndex = this.route.snapshot.queryParams['unitIndex'] || 0;
    this.selectedUnit = this.currentLevel[unitIndex] || this.currentLevel[0];
    this.getUnitData();
  }

  updateUnitFilter(): void {
    const unitIndex = this.currentLevel.indexOf(this.selectedUnit);

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { unitIndex: unitIndex },
    });

    this.getUnitData();
  }
  getUnitData(): void {
    this.activeAudio = null;
    this.activeCard = null;
    this.cardRows = this.selectedUnit.rows.map((rowData) => {
      const cols = rowData.split(',');
      return cols.map((cardData) =>
        this.cardService.generateCard(cardData.trim(), true)
      );
    });
  }

  getRowIndex(index: number): number {
    return index;
  }

  drop(e: CdkDragDrop<Card>): void {
    this.setActiveCard(e.item.data);
  }

  setActiveCard(card: Card): void {
    if (this.activeCard) {
      this.clearActiveCard();
    }

    card.isHidden = true;
    this.activeAudio = new Audio(card.filePath);
    this.activeCard = card;
    this.playActiveCardAudio();
  }

  playActiveCardAudio(): void {
    this.activeAudio.play();
  }

  clearActiveCard(): void {
    this.activeAudio.pause();
    this.activeAudio = null;
    let activeCardIndex = -1;
    const activeRowIndex = this.cardRows.findIndex(
      (row) =>
        row.findIndex((card, index) => {
          if (card.filePath === this.activeCard.filePath) {
            activeCardIndex = index;
            return true;
          }

          return false;
        }) !== -1
    );

    // update the active row
    const activeRow = this.cardRows[activeRowIndex];
    activeRow.splice(activeCardIndex, 1, {
      ...this.activeCard,
      isHidden: false,
    });

    // update the sound rows & active card
    this.cardRows.splice(activeRowIndex, 1, activeRow);
    this.activeCard = null;
  }
}

export interface SoundCardData {
  [programId: string]: CardUnit[];
}
