import { Component, Input } from '@angular/core';
import { DropdownComponent } from '@wilson/wilsonng';
import { InitialLoadService } from 'src/app/services/initial-load.service';
import { Wilson } from 'src/def/Wilson';
import WidgetEnrollmentResource = Wilson.WidgetEnrollmentResource;
import WidgetTraining = Wilson.WidgetTraining;

@Component({
  selector: 'app-academy-widget',
  templateUrl: './academy-widget.component.html',
  styleUrls: ['./academy-widget.component.scss'],
})
export class AcademyWidgetComponent {
  @Input() data: WidgetEnrollmentResource;
  @Input() flagText = '';
  @Input() flagClass = '';
  @Input() styleClass = '';
  selectedTraining: WidgetTraining;

  constructor(private initialLoadService: InitialLoadService) {}

  openTraining(trainings: DropdownComponent): void {
    if (this.selectedTraining) {
      window.open(this.selectedTraining.trainingUrl, '_blank');
      trainings.clear();
    }
  }

  addS(value: number): string {
    return value === 1 ? '' : 's';
  }

  tileClick(): void {
    window.open(
      `https://${
        this.initialLoadService.initialLoad.isProduction ? '' : 'dev-academy.'
      }wilsonacademy.com/`,
      '_blank'
    );
  }
}
