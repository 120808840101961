import { Injectable } from '@angular/core';
import { AuthenticationService } from '@wilson/wilsonng';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResourceService } from './resource.service';
import mixpanel from 'mixpanel-browser';
import { Wilson } from 'src/def/Wilson';
import ProgramResource = Wilson.ProgramResource;

@Injectable({
  providedIn: 'root',
})
export class FunnelService {
  private _currentFunnelSubject = new BehaviorSubject<ProgramResource>(null);
  private currentFunnel = this._currentFunnelSubject.asObservable();

  private funnels: ProgramResource[] = [];
  private favicon: HTMLLinkElement = document.querySelector('#favicon');

  constructor(
    private resourceService: ResourceService,
    private authenticationService: AuthenticationService
  ) {
    /* istanbul ignore if */
    if (window['Cypress']) {
      window['FunnelService'] = this;
    }
  }

  getCurrentFunnel(): ProgramResource {
    return this._currentFunnelSubject.value;
  }

  watchCurrentFunnel(): Observable<ProgramResource> {
    return this.currentFunnel;
  }

  updateCurrentFunnel(value: string): void {
    if (this.isFunnelValid(value)) {
      const funnelChanged = this._currentFunnelSubject.value?.id !== value;
      if (!funnelChanged) {
        return;
      }

      // set to cached funnel then make request to update
      const cachedFunnel = this.getFunnelConfiguration(value);
      this._currentFunnelSubject.next(cachedFunnel);

      //update mixpanel
      mixpanel.register({ 'Triggered From Program': value });

      // update funnel from server
      this.resourceService
        .getResource(value)
        .pipe(
          tap((newFunnel: ProgramResource) => {
            this._currentFunnelSubject.next(newFunnel);

            // update the favicon
            if (!this.favicon) {
              this.favicon = document.querySelector('#favicon');
            }

            this.favicon.href = newFunnel.iconImageUrl;
          })
        )
        .subscribe();
    }
  }

  isFunnelValid(funnel: string): boolean {
    return (
      this.funnels
        .map((f) => f.id?.toLowerCase())
        .indexOf(funnel.toLowerCase()) > -1
    );
  }

  resetFunnel(): void {
    this._currentFunnelSubject.next(null);
  }

  loadFunnels(): Observable<ProgramResource[]> {
    // Only try to get token if user is actually authed
    const getPrograms = this.authenticationService.isLoggedIn()
      ? this.resourceService.getPrograms()
      : of(null);
    return getPrograms.pipe(
      tap((programs: ProgramResource[]) => {
        this.funnels = programs || [];
        // might be a reload of the funnel array, reset to current funnel
        if (this._currentFunnelSubject?.value?.id) {
          this._currentFunnelSubject.next(
            this.getFunnelConfiguration(this._currentFunnelSubject?.value?.id)
          );
        }
      })
    );
  }

  getFunnels(): ProgramResource[] {
    return this.funnels;
  }

  getFunnelConfiguration(id: string): ProgramResource {
    return this.funnels.find((f) => f.id?.toLowerCase() === id.toLowerCase());
  }
}
