import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import ResourceType = Wilson.ResourceType;
import NotificationFlag = Wilson.NotificationFlag;

@Injectable({
  providedIn: 'root',
})
export class MockDataService {
  getFilterGroups(groupId: string): Observable<FilterGroup[]> {
    return of([
      {
        id: groupId + '-type',
        title: 'type',
        values: [
          {
            label: 'Demonstrations',
            value: '480a4d8a-e45a-4769-9a72-e58f9f0cdc68',
          },
          {
            label: 'Revisions/Supplements',
            value: '3b4c1367-c818-42fe-ba82-b515e3a01e00',
          },
          { label: 'Planning', value: '9f8c4ece-30b0-4026-bdfe-0e144520fff8' },
          {
            label: 'Collaboration',
            value: 'd9c9b9a8-d90c-489b-80e0-10851f2b7db3',
          },
          {
            label: 'Reference List',
            value: '82ba2fe0-cc9f-469d-8fb6-13cf1aae13a2',
          },
        ],
      },
      {
        id: groupId + '-level',
        title: 'level',
        values: [
          { label: 'Level K', value: '46b32bf6-521f-4cce-9125-3cbeeff552ed' },
          { label: 'Level 1', value: 'c67cece9-68b2-4081-a8e1-087175a379e3' },
          { label: 'Level 2', value: '0dd5b83b-bd9b-47e5-b358-3b625185edcb' },
          { label: 'Level 3', value: 'c26785e9-4e25-4ba3-92f2-091cc47b0e86' },
        ],
      },
    ]);
  }

  getRandomTags(): FlatTag[] {
    // random group tag
    const groups = [
      { id: '480a4d8a-e45a-4769-9a72-e58f9f0cdc68', name: 'Group 1' },
      { id: '3b4c1367-c818-42fe-ba82-b515e3a01e00', name: 'Group 2' },
      { id: '9f8c4ece-30b0-4026-bdfe-0e144520fff8', name: 'Group 3' },
      { id: 'd9c9b9a8-d90c-489b-80e0-10851f2b7db3', name: 'Group 4' },
      { id: '82ba2fe0-cc9f-469d-8fb6-13cf1aae13a2', name: 'Group 5' },
    ];
    const levels = [
      { id: '46b32bf6-521f-4cce-9125-3cbeeff552ed', name: 'Level 1' },
      { id: 'c67cece9-68b2-4081-a8e1-087175a379e3', name: 'Level 2' },
      { id: '0dd5b83b-bd9b-47e5-b358-3b625185edcb', name: 'Level 3' },
      { id: 'c26785e9-4e25-4ba3-92f2-091cc47b0e86', name: 'Level 4' },
    ];

    const r1 = Math.floor(Math.random() * Object.keys(groups).length);
    const r2 = Math.floor(Math.random() * Object.keys(levels).length);

    return [groups[r1] as FlatTag, levels[r2] as FlatTag];
  }

  makeMockResource(id: string): Resource {
    const r = Math.floor(Math.random() * 10) + 1;
    const validTypes = [ResourceType.pdf, ResourceType.video];
    const type = validTypes[Math.floor(Math.random() * validTypes.length)];
    return {
      id,
      type,
      name: generateRandomString(10),
      description: generateRandomString(500),
      imageUrl: `https://picsum.photos/seed/${id}/320/160`,
      tags: this.getRandomTags(),
      attachedResources: null,
      notification: {
        text: r < 3 ? 'New' : null,
        show: true,
        override: null,
      },
      requiredSubscription:
        r < 2
          ? {
              name: '',
              signUpLink: 'https://www.google.com',
              subscription: 'mock',
            }
          : null,
      url: `https://google.com/search?q=${generateRandomString(10)}`,
      relatedResources: null,
      isPinned: r < 5,
      isSearchable: true,
      isSharingPermitted: false,
    };
  }

  genResources<T = Resource>(id: string, num: number): T[] {
    const output = [];
    for (let i = 0; i < num; i++) {
      const newId = `${id}-${i}`;
      const res = this.makeMockResource(newId);
      output.push(res);
    }
    return output;
  }
}

const generateRandomString = (length = 6) => {
  const grs = (len = length, randomString = '') => {
    randomString += Math.random().toString(20).substring(2, len);
    if (randomString.length > len) return randomString.slice(0, len);
    return grs(len, randomString);
  };

  let str = grs(length);

  if (length > 10) {
    const r = Math.floor(Math.random() * 5) + 10 - 5;
    str = str
      .split('')
      .map((char, index) => {
        if (index > 0 && index % r === 0) {
          return ' ';
        } else {
          return char;
        }
      })
      .join('');
  }

  return str;
};

interface RequiredSubscription {
  name: string;
  subscription: string;
  signUpLink: string;
}

interface FlatTag {
  id: string;
  name: string;
  resourceTypes: ResourceType[];
  programIds: string[];
}

interface Resource {
  id: string;
  name: string;
  type: ResourceType;
  description: string;
  descriptionFormatted?: string;
  imageUrl: string;
  tags: FlatTag[];
  notification: {
    text: string;
    show: true;
    override: NotificationFlag;
  };
  requiredSubscription: RequiredSubscription;
  attachedResources: Resource[];
  relatedResources: Resource[];
  isPinned: boolean;
  isSearchable: true;
  isSharingPermitted: false;

  // collections specific fields
  childResources?: Resource[];

  // iframe, external link, pdf, and video specific field
  url?: string;

  // widget specific field
  subType?: string;
}

interface FilterGroup {
  id: string;
  title: string;
  values: { [key: string]: string }[];
}
