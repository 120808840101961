import { Component, Input, OnInit } from '@angular/core';
import { ApiService, DropdownComponent } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CardUnit } from 'src/app/services/card.service';
import { FunnelService } from 'src/app/services/funnel.service';
import mixpanel from 'mixpanel-browser';
import { Wilson } from 'src/def/Wilson';
import WidgetWrsCardActivity = Wilson.WidgetWrsCardActivity;

@Component({
  selector: 'app-wrs-card-activity-widget',
  templateUrl: './wrs-card-activity-widget.component.html',
  styleUrls: ['./wrs-card-activity-widget.component.scss'],
})
export class WrsCardActivityWidgetComponent implements OnInit {
  @Input() data: WidgetWrsCardActivity;
  @Input() flagText = '';
  @Input() flagClass = '';
  @Input() styleClass = '';
  steps: Partial<CardUnit>[] = [];
  activeStep: Partial<CardUnit>;
  activityUrl: string;

  constructor(
    private funnelService: FunnelService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.getSteps().subscribe();
    this.setActivityUrl();
  }

  getSteps(): Observable<CardUnit[]> {
    return this.apiService
      .getLocalAsset<CardUnit[]>('/assets/data/wrs-cards.json')
      .pipe(
        tap((data) => {
          this.steps = data;
        })
      );
  }

  goToSelectedStep(steps: DropdownComponent = null): void {
    if (this.activeStep) {
      const stepNumber =
        this.steps.findIndex((step) => step.title === this.activeStep.title) +
        1;
      window.open(`${this.activityUrl}?step=${stepNumber}`, '_blank');
      steps.clear();
      mixpanel.track('Go to Resource', {
        'Resource Name': this.data.name,
        'Resource Type': this.data.type,
        'Resource ID': this.data.id,
      });
    }
  }

  tileClick(): void {
    window.open(this.activityUrl, '_blank');
  }

  private setActivityUrl(): void {
    const currentFunnel = this.funnelService.getCurrentFunnel().id;
    this.activityUrl = `${window.location.origin}/${currentFunnel}/${this.data.activityUrl}`;
  }
}
