<header #header>
  <nav
    class="container-fluid site-header has-icon"
    role="navigation"
    aria-label="main"
    [class.menu-open]="menuOpen"
    [class.admin-funnel]="isAdmin"
    (keyup)="showNavOptions && userMenuKeyboard($event, userMenuBtn)"
  >
    <div class="header-container">
      <div class="nav-left">
        <a
          [routerLink]="headerDetails.homeUrl"
          class="icon-img"
          [class.pe-none]="!showNavOptions"
          (click)="programIconClick()"
          data-automationId="header_home"
        >
          <img
            *ngIf="headerDetails.iconUrl"
            [src]="headerDetails.iconUrl"
            alt="{{ headerDetails.funnelName }} icon"
          />
        </a>
        <div class="wlt-title">
          <div class="primary" [innerHtml]="headerDetails.title"></div>
          <div
            *ngIf="funnelType === ft.community"
            class="secondary"
            [innerHtml]="headerDetails.subtitle"
          ></div>
        </div>
        <span *ngIf="isAdmin" class="build-number">
          {{ headerDetails.versionNumber }}
          {{
            headerDetails.buildNumber ? ' - ' + headerDetails.buildNumber : ''
          }}
        </span>
      </div>
      <div class="nav-right">
        <button
          class="wlt-btn btn-lighten hide-md"
          *ngIf="showNavOptions"
          (click)="showProgramDialog()"
          data-automationId="header_change_program"
        >
          Change Program
        </button>

        <!-- Program Switcher Modal -->
        <p-dialog
          *ngIf="showNavOptions"
          [(visible)]="displayModal"
          [modal]="true"
          [baseZIndex]="10000"
          [draggable]="false"
          [resizable]="false"
          styleClass="program-dialog"
        >
          <ng-template pTemplate="header">
            <div class="title-box">
              <h3>Change Program</h3>
              <p>Select a program below to switch communities.</p>
            </div>
          </ng-template>
          <div class="row programs">
            <div class="col-12">
              <app-tile-grid
                columnClass="program-card col-12 col-sm-6 col-md-4 col-lg-3"
                tileClass="program-tile"
                [data]="programTiles"
                [pageSize]="programTiles.length"
                [totalItems]="programTiles.length"
                [showPin]="false"
                (tileClicked)="tileAction($event)"
                data-automationId="header_program_switcher_list"
              ></app-tile-grid>
            </div>
          </div>
        </p-dialog>
        <!-- End Program Switcher Modal -->

        <button
          #userMenuBtn
          class="wlt-btn user-menu icon-right hide-md"
          [class.d-none]="!showNavOptions"
          (click)="showNavOptions && userMenu.toggle($event)"
          data-automationId="header_menu_toggle"
        >
          <span aria-hidden="true" class="wlt-avatar">{{ userInitials }}</span>
          <i
            class="fas fa-caret-down"
            aria-hidden="true"
            *ngIf="showNavOptions"
          ></i>
          <span class="visually-hidden">User Menu</span>
        </button>
        <button
          class="hamburger-menu wlt-btn btn-icon d-md-none"
          *ngIf="showNavOptions"
          (click)="userMenu.toggle($event)"
        >
          <i class="far fa-bars" aria-hidden="true"></i>
        </button>

        <p-menu
          #userMenu
          [popup]="true"
          [model]="options"
          [appendTo]="header"
          (onShow)="toggleUserMenu()"
          (onHide)="toggleUserMenu()"
          styleClass="user-menu soft-shadow"
        ></p-menu>
      </div>
    </div>
  </nav>
</header>
