import { HttpBackend, HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Wilson } from 'src/def/Wilson';
import { MockDataService } from './mock-data.service';
import { UserService } from './user.service';
import AnnouncementResource = Wilson.AnnouncementResource;
import BaseResource = Wilson.BaseResource;
import CollectionResource = Wilson.CollectionResource;
import FileResource = Wilson.FileResource;
import ProgramResource = Wilson.ProgramResource;
import Resource = Wilson.Resource;
import VideoResource = Wilson.VideoResource;
import BreadCrumb = Wilson.BreadCrumb;
import SiteMap = Wilson.SiteMap;
import ResourceType = Wilson.ResourceType;
import { ResourceNotificationService } from './resource-notification.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  private controller = 'resource';

  constructor(
    private apiService: ApiService,
    private mockDataService: MockDataService,
    private userService: UserService,
    private httpClient: HttpClient,
    private handler: HttpBackend,
    private resourceNotificationService: ResourceNotificationService
  ) {
    // This is only used to bypass our HTTP interceptors (e.g. AuthHttpInterceptor, CommunitiesInfoInterceptor, etc).
    // We don't want/need them when we make the call to download a resource, since that resource lives on a different server.
    this.httpClient = new HttpClient(this.handler);
  }

  private makeParams(skip: number, take: number): URLSearchParams {
    return new URLSearchParams({
      skip: skip.toString(),
      take: take.toString(),
    });
  }

  getPrograms(): Observable<ProgramResource[]> {
    return this.apiService.get<ProgramResource[]>(
      `${this.controller}/GetPrograms`
    );
  }

  getResource<T extends BaseResource = BaseResource>(
    resourceId: string,
    collectionId = ''
  ): Observable<T> {
    let url = collectionId + (collectionId ? '/' : '');
    url += resourceId;

    return this.apiService
      .get<T>(`${this.controller}/GetResource/${url}`)
      .pipe(map((resource: T) => this.postProcessResource(resource)));
  }

  getCollection(
    collectionId: string,
    skip: number,
    take: number
  ): Observable<CollectionResource> {
    const params = this.makeParams(skip, take);
    return this.apiService.get<CollectionResource>(
      `${this.controller}/GetCollection/${collectionId}?${params}`
    );
  }

  getSubcollection(
    collectionId: string,
    subcollectionId: string,
    skip: number,
    take: number
  ): Observable<CollectionResource> {
    const params = this.makeParams(skip, take);
    return this.apiService.get<CollectionResource>(
      `${this.controller}/GetSubcollection/${collectionId}/${subcollectionId}?${params}`
    );
  }

  getBreadcrumbs(parts: string[]): Observable<BreadCrumb[]> {
    return this.apiService.post<BreadCrumb[]>(
      `${this.controller}/GetBreadcrumbs`,
      parts
    );
  }

  getResources<T = Resource>(groupId: string): Observable<T[]> {
    return of(this.mockDataService.genResources<T>(groupId, 24));
  }

  getAnnouncements(): Observable<AnnouncementResource[]> {
    return this.apiService.get<AnnouncementResource[]>(
      `${this.controller}/GetAnnouncements`
    );
  }

  getLatestUserAnnouncement(): Observable<AnnouncementResource> {
    return this.apiService.get<AnnouncementResource>(
      `${this.controller}/GetLatestUserAnnouncement`
    );
  }

  getSiteMap(): Observable<SiteMap> {
    return this.apiService.get<SiteMap>(`${this.controller}/GetSiteMap`);
  }

  getResourceUrl(
    resourceId: string,
    type: ResourceType
  ): Observable<DownloadInfo> {
    return this.apiService.get<DownloadInfo>(
      `${this.controller}/GetResourceUrl/${type}/${resourceId}`
    );
  }

  vimeoServerChecker(videoUrl: string): Observable<boolean> {
    if (!videoUrl.includes('vimeo')) {
      return of(true);
    }
    return this.apiService.ifServerOnline(
      `https://vimeo.com/api/oembed.json?url=${videoUrl}`
    );
  }

  videoLinkDecider(videoResource: VideoResource): Observable<string> {
    return this.vimeoServerChecker(videoResource.url).pipe(
      mergeMap((isOnline) =>
        isOnline ? of(videoResource.url) : of(videoResource.backupUrl)
      )
    );
  }

  handleFallbackUrl(videoResource: VideoResource): Observable<string> {
    if (videoResource.type !== ResourceType.video) {
      return of(null);
    }
    return this.videoLinkDecider(videoResource).pipe(
      tap((selectedUrl: string) => {
        videoResource.url = selectedUrl;
      })
    );
  }

  private postProcessResource<T extends BaseResource>(resource: T): T {
    if (resource['childResources'] !== undefined) {
      const collection = (<unknown>resource) as CollectionResource;
      collection.childResources = collection.childResources.map((child) =>
        this.postProcessResource(child as Resource)
      );
    }

    (resource as unknown as Resource).relatedResources = (
      resource as unknown as Resource
    ).relatedResources?.map((related: Resource) =>
      this.postProcessResource(related)
    );

    this.resourceNotificationService.determineNotifications(resource);
    this.determinePinStatus(resource);

    return resource;
  }

  private determinePinStatus(resource: BaseResource): void {
    (resource as Resource).isPinned =
      this.userService.user.resourcesPinned?.includes(resource.id);
  }

  private getResourceBlob(
    url: string,
    filename: string
  ): Observable<HttpResponse<Blob>> {
    return this.httpClient
      .get(url, { responseType: 'blob', observe: 'response' })
      .pipe(
        tap((blob: HttpResponse<Blob>) => {
          const blobUrl = window.URL.createObjectURL(blob.body);
          this.forceDownload(blobUrl, filename);
        }),
        catchError((e) => {
          console.error(e);
          return of(null);
        })
      );
  }

  private forceDownload(blobUrl: string, fileName: string): void {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = blobUrl;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
  downloadResource(resource: BaseResource): Observable<HttpResponse<Blob>> {
    if (
      resource.type !== ResourceType.pdf &&
      resource.type !== ResourceType.file
    ) {
      console.error('A download was attempted on a non-downloadable resource');
      return;
    }
    const resourceId = resource.id;
    const fileName = (resource as FileResource).fileName || resource.name;

    return this.getResourceUrl(resourceId, resource.type).pipe(
      switchMap((download) => this.getResourceBlob(download.url, fileName))
    );
  }
}

export enum AuditType {
  created = 'created',
  modified = 'modified',
  deleted = 'deleted',
}
export interface DownloadInfo {
  id: string;
  url: string;
}

// search things
export interface FilterGroup {
  title: string;
  values: FilterGroupTag[];
}

export interface FilterGroupTag {
  id: string;
  value: string;
  count: number;
}

// collection types
export enum CollectionType {
  primitives = 'cp',
  collections = 'cc',
}

export type ChildType = 'primitive' | ResourceType;
