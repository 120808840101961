<wilson-tile
  [imageSrc]="data.imageUrl"
  imageAlt=""
  [flagText]="flagText"
  [flagClass]="flagClass"
  [styleClass]="styleClass + ' academy-widget'"
  (tileClicked)="tileClick()"
  [clickable]="true"
>
  <h4 class="tile-heading">{{ data.name }}</h4>
  <ul class="badge-block">
    <li>
      <span class="tile-badge" [class.active]="data.trainings.length > 0">{{
        data.trainings.length
      }}</span>
      Enrollment{{ addS(data.trainings.length) }}
    </li>
    <li>
      <span
        class="tile-badge"
        [class.active]="data.activeAssignmentsCount > 0"
        >{{ data.activeAssignmentsCount }}</span
      >
      Incomplete Assignment{{ addS(data.activeAssignmentsCount) }}
    </li>
  </ul>
  <div class="tile-description">
    <div class="w-100 mt-auto" (click)="$event.stopPropagation()">
      <wilson-dropdown
        #trainings
        [showListOnFocus]="true"
        *ngIf="data.trainings.length > 0"
        [options]="data.trainings"
        [(ngModel)]="selectedTraining"
        (ngModelChange)="openTraining(trainings)"
        optionLabel="name"
        placeholder="Choose enrollment"
      ></wilson-dropdown>
    </div>
  </div>

  <a
    *ngIf="data.trainings.length === 0"
    class="tile-link"
    title="Wilson Academy"
    href="https://wilsonacademy.com/ProfessionalLearning"
    target="_blank"
  >
    View Professional Learning »
  </a>

  <ng-content></ng-content>

  <div tile-header>
    <ng-content select="[tile-header]"></ng-content>
  </div>
</wilson-tile>
