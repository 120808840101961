import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApiService,
  DropdownComponent,
  UtilityService,
} from '@wilson/wilsonng';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CardService, CardUnit, KeyBind } from 'src/app/services/card.service';

@Component({
  selector: 'app-card-activities',
  templateUrl: './card-activities.component.html',
  styleUrls: ['./card-activities.component.scss'],
})
export class CardActivitiesComponent implements OnInit {
  copyrightYears: string;
  enableDrawing = false;
  steps: Partial<CardUnit>[] = [];
  activeStep: Partial<CardUnit>;
  @ViewChild('stepSelector') stepSelector: DropdownComponent;

  // accessibility features
  keyboardModeEnabled = false;
  keyboardHelpVisible = false;
  kb = KeyBind;
  altKey = 'Alt';

  constructor(
    private utilityService: UtilityService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private cardService: CardService
  ) {}

  ngOnInit(): void {
    this.determineOptionKey(window.navigator);
    this.copyrightYears = this.utilityService.getCopyrightYearsString(2021);
    this.getSteps().subscribe();
  }

  toggleDrawing(): void {
    this.enableDrawing = this.enableDrawing ? false : true;
  }

  getSteps(): Observable<CardUnit[]> {
    return this.apiService
      .getLocalAsset<CardUnit[]>('/assets/data/wrs-cards.json')
      .pipe(
        tap((data) => {
          this.steps = data;
          this.setActiveStep();
        })
      );
  }

  setActiveStep(): void {
    const stepIndex = +this.route.snapshot.queryParams.step - 1;

    // set the active step to the first one that isn't disabled
    this.activeStep = this.steps[stepIndex];
  }

  updateStep(): void {
    const stepNumber =
      this.steps.findIndex((step) => step.title === this.activeStep.title) + 1;
    this.router.navigate([], {
      queryParams: {
        step: stepNumber,
      },
    });
  }

  // ==============================
  // KEYBOARD ACCESSIBILITY
  // ==============================
  /** shows a toast notification when actions are
   * triggered using the keyboard
   */
  showMessage(summary: string, force = false): void {
    if (this.keyboardModeEnabled || force) {
      this.messageService.clear();
      this.messageService.add({
        summary,
        closable: false,
      });
    }
  }

  determineOptionKey(nav: Navigator) {
    const userAgent = nav.userAgent;
    const optionKeyPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    this.altKey = optionKeyPlatforms.includes(userAgent) ? 'Option' : 'Alt';
  }

  toggleKeyboardMode() {
    this.keyboardModeEnabled = !this.keyboardModeEnabled;
    this.showMessage(
      `Keyboard mode ${this.keyboardModeEnabled ? 'enabled' : 'disabled'}`,
      true
    );
  }

  toggleKeyboardHelp() {
    this.keyboardHelpVisible = !this.keyboardHelpVisible;
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyPressed(event: KeyboardEvent) {
    if (event.ctrlKey) {
      return;
    }

    const bind = this.cardService.toBind(event.code);
    let handled = false;
    if (event.altKey) {
      if (this.keyboardModeEnabled && bind === KeyBind.HelpToggle) {
        this.toggleKeyboardHelp();
        handled = true;
      } else if (bind === KeyBind.KeyboardToggle) {
        this.toggleKeyboardMode();
        handled = true;
      }
    } else if (
      this.keyboardModeEnabled &&
      event.shiftKey &&
      bind === KeyBind.StepSelector
    ) {
      this.stepSelector.trigger.nativeElement.focus();
      handled = true;
    }

    if (handled || this.keyboardHelpVisible) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  getKey = this.cardService.getKey;
}

export const Selectors = {
  BlankCard: '[data-blank]:not([data-blank="false"]',
  Card: '.wlt-card',
  CardRow: '.card-row',
  DragPreview: '.cdk-drag-preview',
  Frame: '.frame',
  FrameRow: '.frame-row',
  Focusable: '[tabindex]:not([tabindex="-1"]',
  ItemList: '.item-list',
};
