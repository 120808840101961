import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TeacherSupportService {
  private _displayGettingStartedDialogSubject = new BehaviorSubject<boolean>(
    false
  );
  private displayGettingStartedDialog =
    this._displayGettingStartedDialogSubject.asObservable();

  watchDisplayGettingStartedDialog(): Observable<boolean> {
    return this.displayGettingStartedDialog;
  }

  updateDisplayGettingStartedDialog(value: boolean): void {
    this._displayGettingStartedDialogSubject.next(value);
  }
}
