<div class="d-inline-block" (click)="togglePin($event)">
  <ng-container
    *ngTemplateOutlet="template ? template : defaultPinButton"
  ></ng-container>
</div>

<ng-template #defaultPinButton>
  <button
    class="btn-pin {{ styleClass }}"
    [class.pinned]="resource.isPinned"
    title="{{ resource.isPinned ? 'Unpin' : 'Pin' }} this resource"
    data-automationId="tile_pin_resource"
    type="button"
  >
    <i aria-hidden="true" class="fas fa-heart"></i>
    <span class="visually-hidden"
      >{{ resource.isPinned ? 'Unpin' : 'Pin' }} this resource</span
    >
  </button>
</ng-template>
