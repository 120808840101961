<ng-container [ngSwitch]="true">
  <ng-container
    *ngSwitchCase="isStripped"
    [ngTemplateOutlet]="strippedTile"
  ></ng-container>
  <ng-container
    *ngSwitchCase="isWidget"
    [ngTemplateOutlet]="widgetTile"
  ></ng-container>
  <ng-container
    *ngSwitchCase="isAttachment"
    [ngTemplateOutlet]="attachmentTile"
  ></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="linkTile"></ng-container>
</ng-container>

<ng-template #tileHeader>
  <app-pin-button
    *ngIf="showPin"
    styleClass="tile-btn"
    (pinStatusChanged)="emitPinStatusChange($event)"
    [resource]="data"
  ></app-pin-button>
  <ng-content select="[tile-header]"></ng-content>
</ng-template>

<ng-template #strippedTile>
  <wilson-tile
    [imageSrc]="data.imageUrl"
    imageAlt=""
    [styleClass]="styleClass + ' stripped'"
  >
    <h4>{{ data.name | stripHtml }}</h4>
    <p>
      {{
        (data.description?.length > 80
          ? data.description.substring(0, 80) + '...'
          : data.description
        ) | stripHtml
      }}
    </p>
  </wilson-tile>
</ng-template>

<ng-template #widgetTile>
  <app-widget
    [data]="data"
    [flagText]="flagText"
    [flagClass]="flagClass"
    [styleClass]="styleClass"
  >
    <ng-container
      ngProjectAs="'[tile-header]'"
      *ngTemplateOutlet="tileHeader"
    ></ng-container>
    <ng-content></ng-content>
  </app-widget>
</ng-template>

<ng-template #attachmentTile>
  <!-- button attachments -->
  <button
    (click)="handleClick($event)"
    class="soft-shadow attachment-tile hoverable {{ styleClass }}"
    [class.no-sub]="data.requiredSubscription"
    [title]="
      data.requiredSubscription
        ? 'Learn More'
        : 'Open ' + (data.type | userFriendlyResourceType : true)
    "
  >
    <ng-container *ngTemplateOutlet="attachmentContent"></ng-container>
  </button>

  <ng-template #attachmentContent>
    <img [src]="data.imageUrl" />
    <span class="attachment-icon" [class.red]="data.requiredSubscription">
      <i
        aria-hidden="true"
        [class]="icons[!!data.requiredSubscription || data.type]"
      ></i>
    </span>
    <div class="tile-body">
      <h4
        [title]="data.name | stripHtml"
        class="tile-heading"
        [class.shortened]="data.requiredSubscription"
      >
        {{ data.name }}
      </h4>
      <p *ngIf="data.requiredSubscription" class="meta">
        <em>Subscription Required</em>
      </p>
      <p [title]="data.description | stripHtml" class="tile-description">
        {{ data.description }}
      </p>
    </div>
  </ng-template>
</ng-template>
<ng-template #linkTile>
  <wilson-link-tile
    [imageSrc]="imageUrl"
    imageAlt=""
    [isSkeleton]="name === '...'"
    [heading]="name"
    [description]="description"
    [styleClass]="extraClasses + ' ' + styleClass"
    [class.noShare]="isMultiShareModeActive && !data.isSharingPermitted"
    [flagText]="flagText"
    [flagClass]="flagClass"
    [footerLink]="showFooterLink"
    [linkText]="isMultiShareModeActive ? '' : actualLinkText"
    (tileClicked)="handleTileClickOrMultiShare(data, $event)"
    data-automationId="link_tile"
  >
    <ng-container
      ngProjectAs="'[tile-header]'"
      *ngTemplateOutlet="tileHeader"
    ></ng-container>
    <ng-content></ng-content>
    <div tile-header *ngIf="userIsAdmin">
      <button
        class="btn-edit tile-btn"
        [class.no-pin]="!showPin"
        title="Edit this resource"
        type="button"
        (click)="editResourceClick($event)"
      >
        <i aria-hidden="true" class="fas fa-pencil"></i>
        <span class="visually-hidden">Edit this resource</span>
      </button>
    </div>
    <!--Individual Sharelink buttons-->
    <div
      class="shareButtonsContainer"
      *ngIf="!isMultiShareModeActive && data.isSharingPermitted"
    >
      <button
        class="tile-link"
        pTooltip="Share via email"
        (click)="shareEmail(data.id); $event.stopPropagation()"
        data-automationId="client_tile_share_via_email"
      >
        <i aria-hidden="true" class="fas fa-envelope icon"></i>
      </button>
      <button
        class="tile-link"
        pTooltip="Share via Classroom"
        (click)="shareClassRoom(data.id); $event.stopPropagation()"
        data-automationId="client_tile_share_via_classroom"
      >
        <i aria-hidden="true" class="fab fa-google icon"></i>
      </button>
      <button
        class="tile-link"
        pTooltip="Copy link to clipboard"
        (click)="sharelinkToClipboard(data.id); $event.stopPropagation()"
        data-automationId="client_tile_share_multiple_clipboard"
      >
        <i aria-hidden="true" class="fas fa-link icon"></i>
      </button>
      <!--End Sharelink buttons-->
    </div>
  </wilson-link-tile>
</ng-template>
