<div
  id="cardActivities"
  class="container-fluid has-swoop {{
    !keyboardModeEnabled && enableDrawing ? 'draw-mode' : ''
  }}"
>
  <div class="swoop-background"></div>
  <div class="d-flex flex-column p-unselectable-text">
    <header class="container d-flex justify-content-between py-2">
      <div>
        <img
          class="app-logo"
          src="../../../assets/img/wrs-white.svg"
          alt="Wilson Reading System Logo"
        />
      </div>
      <div class="keyboard-actions d-none d-md-flex align-items-center">
        <button
          pButton
          class="keyboard-toggle {{
            keyboardModeEnabled ? 'btn-light visible' : 'btn-primary'
          }}"
          (click)="toggleKeyboardMode()"
        >
          <i aria-hidden="true" class="fas fa-keyboard me-2"></i>
          {{ keyboardModeEnabled ? 'Disable' : 'Enable' }} Keyboard mode
        </button>
        <ng-container *ngIf="keyboardModeEnabled">
          <button pButton class="ms-2 btn-light" (click)="toggleKeyboardHelp()">
            <i aria-hidden="true" class="fas fa-question-circle me-2"></i>
            Keyboard Help
          </button>

          <p-dialog header="Keyboard Help" [(visible)]="keyboardHelpVisible">
            <div class="row">
              <div class="col">
                <div class="group">
                  <h4>Toggles</h4>
                  <ul>
                    <li>
                      <strong>
                        {{ altKey }} + {{ getKey(kb.KeyboardToggle) }}:
                      </strong>
                      Keyboard mode
                    </li>
                    <li>
                      <strong>
                        {{ altKey }} + {{ getKey(kb.HelpToggle) }}:</strong
                      >
                      Keyboard help window
                    </li>
                  </ul>
                </div>

                <div class="group mt-2">
                  <h4>Actions</h4>
                  <ul>
                    <li>
                      <strong>
                        {{ altKey }} + {{ getKey(kb.ClearItems) }}:</strong
                      >
                      Clear cards from drop rows
                    </li>
                    <li>
                      <strong>
                        {{ altKey }} + Shift + {{ getKey(kb.ClearItems) }}:
                      </strong>
                      Clear cards and frames from drop rows
                    </li>
                    <li>
                      <strong>
                        {{ altKey }} + {{ getKey(kb.DeleteItem) }}:
                      </strong>
                      Remove selected card/frame
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col">
                <div class="group">
                  <h4>Moving Around</h4>
                  <ul>
                    <li>
                      <strong>{{ getKey(kb.MoveUp) }}</strong> or
                      <strong>{{ getKey(kb.MoveDown) }}:</strong>
                      Move current card/frame between drop rows
                    </li>
                    <li>
                      <strong>{{ getKey(kb.MoveLeft) }}</strong> or
                      <strong>{{ getKey(kb.MoveRight) }}:</strong>
                      Move current card/frame left/right in drop row
                    </li>
                  </ul>
                </div>

                <div class="group mt-2">
                  <h4>Focus</h4>
                  <ul>
                    <li>
                      <strong>Shift + {{ getKey(kb.DropRow1) }}</strong> or
                      <strong>{{ getKey(kb.DropRow2) }}:</strong>
                      Focus on drop row 1 or 2
                    </li>
                    <li>
                      <strong>Shift + {{ getKey(kb.FirstFrame) }}:</strong>
                      Focus on first frame in grid
                    </li>
                    <li>
                      <strong>Shift + {{ getKey(kb.FirstCard) }}:</strong>
                      Focus on first card in grid
                    </li>
                    <li>
                      <strong>Shift + {{ getKey(kb.FirstBlankCard) }}:</strong>
                      Focus on first blank card in grid
                    </li>
                    <li>
                      <strong>Shift + {{ getKey(kb.NextRow) }}:</strong>
                      Focus on first card in next grid row
                    </li>
                    <li>
                      <strong>Shift + {{ getKey(kb.PrevRow) }}:</strong>
                      Focus on first card in previous grid row
                    </li>
                    <li>
                      <strong>Shift + {{ getKey(kb.StepSelector) }}:</strong>
                      Focus on step selector
                    </li>
                    <li>
                      <strong>A - Z:</strong>
                      Cycle through cards starting with pressed letter
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </p-dialog>
        </ng-container>
      </div>
      <div class="app-actions d-none d-lg-flex align-items-center">
        <label class="mx-2" for="stepSelector">Select a Step:</label>
        <wilson-dropdown
          #stepSelector
          placeholder="Select one"
          styleClass="step-dropdown"
          inputId="stepSelector"
          [showListOnFocus]="true"
          [options]="steps"
          [(ngModel)]="activeStep"
          (ngModelChange)="updateStep()"
          optionLabel="title"
        ></wilson-dropdown>

        <button
          *ngIf="!keyboardModeEnabled"
          pButton
          class="ms-3 {{ enableDrawing ? 'btn-primary' : 'btn-light' }}"
          (click)="toggleDrawing()"
        >
          <i aria-hidden="true" class="fal fa-pencil-alt me-2"></i>
          {{ enableDrawing ? 'Stop Drawing' : 'Enable Drawing' }}
        </button>
      </div>
    </header>

    <app-wrs-card-activity
      [step]="activeStep?.title"
      [drawingEnabled]="!keyboardModeEnabled && enableDrawing"
      [keyboardModeEnabled]="keyboardModeEnabled"
      (message)="showMessage($event)"
    ></app-wrs-card-activity>

    <footer class="container d-flex justify-content-between mt-1">
      <p class="m-0">
        &copy; {{ copyrightYears }} Wilson Language Training Corp.
        All&nbsp;Rights&nbsp;Reserved.
      </p>
      <p class="m-0">
        <a
          href="https://wilsonlanguagetraining.wufoo.com/forms/wilson-digital-products-feedback?RelatedPage=/wilson-reading-system/activities"
          target="_blank"
          >Feedback</a
        >
      </p>
    </footer>
  </div>
</div>
