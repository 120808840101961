import { Injectable } from '@angular/core';
import { Wilson } from 'src/def/Wilson';
import ResourceType = Wilson.ResourceType;
import BaseResource = Wilson.BaseResource;

@Injectable({
  providedIn: 'root',
})
export class ResourceNotificationService {
  public determineNotifications(resource: BaseResource) {
    if (resource.notification?.text || resource.type === ResourceType.program) {
      // we already have a notification from the server or this is a program resource, return
      return;
    }

    const weekAgo = new Date();
    weekAgo.setDate(weekAgo.getDate() - 7);
    resource.notification = resource.notification || {
      override: null,
      text: null,
      show: false,
    };

    if (this.determineNotificationOverride(resource)) {
      resource.notification.text = resource.notification.override.text;
    } else if (new Date(resource.createdDate) > weekAgo) {
      resource.notification.text = 'New';
    } else if (new Date(resource.lastModifiedDate) > weekAgo) {
      resource.notification.text = 'Updated Recently';
    }
  }

  private determineNotificationOverride(resource: BaseResource): boolean {
    return (
      resource.notification?.override?.isPublished &&
      (!resource.notification.override.publishExpirationDate ||
        new Date(resource.notification.override.publishExpirationDate) >
          new Date())
    );
  }
}
