import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceTypePipe } from './resource-type.pipe';

@NgModule({
  declarations: [ResourceTypePipe],
  providers: [ResourceTypePipe],
  imports: [CommonModule],
  exports: [ResourceTypePipe],
})
export class ResourceTypePipeModule {}
