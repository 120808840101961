import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CollectionType } from 'src/app/services/resource.service';
import { TileClickEvent } from 'src/app/components/tile/tile.component';
import { TeacherSupportService } from 'src/app/services/teacher-support.service';
import { AdminService } from 'src/app/services/admin.service';
import { InitialLoadService } from 'src/app/services/initial-load.service';
import { UxService } from 'src/app/services/groups/ux.service';
import { HeaderService } from 'src/app/services/groups/header.service';
import pkg from '../../../../package.json';
import mixpanel from 'mixpanel-browser';
import { Wilson } from 'src/def/Wilson';
import ProgramResource = Wilson.ProgramResource;
import ProgramType = Wilson.ProgramType;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentFunnel: ProgramResource;
  funnelType: ProgramType;
  options: MenuItem[];
  userName: string;
  userInitials: string;
  displayModal = false;
  programTiles: ProgramResource[];
  menuOpen = false;
  pageUrl: string;
  contactId: string;

  headerDetails = {
    funnelName: 'Wilson Communities',
    title: 'Wilson',
    subtitle: '',
    iconUrl: '/assets/favicon.svg',
    homeUrl: '/',
    versionNumber: '',
    buildNumber: '',
  };

  // for markup only
  ft = ProgramType;

  @Input() isAdmin = false;
  @Input() showNavOptions = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private teacherSupportService: TeacherSupportService,
    private initialLoadService: InitialLoadService,
    private adminService: AdminService,
    private header: HeaderService,
    private ux: UxService
  ) {}

  ngOnInit(): void {
    this.initializeFunnel().subscribe();
    this.getUserData();
    this.getProgramData();
    this.initSharelinkHeader();
    this.initHubLandingHeader();
  }

  getUserData(): void {
    if (this.header.authenticationService.isLoggedIn()) {
      const user = this.header.authenticationService.currentUser;
      this.userName = user.name;
      this.userInitials = user.initials;
      this.contactId = user.wilsonId;
    }
  }

  initializeFunnel(): Observable<ProgramResource> {
    return this.header.funnelService.watchCurrentFunnel().pipe(
      tap((funnel: ProgramResource) => {
        const link = this.isAdmin ? '/admin' : '';

        if (!funnel) {
          this.header.titleService.setQualifier(this.headerDetails.funnelName);
          this.headerDetails.homeUrl = link;
          return;
        }

        this.currentFunnel = funnel;
        this.headerDetails.funnelName = funnel.name;
        this.funnelType =
          ProgramType[this.header.funnelService.getCurrentFunnel().subType];

        this.setHeaderTitles();

        this.headerDetails.iconUrl = funnel.iconImageUrl;
        this.headerDetails.homeUrl = `${link}/${funnel.id}`;

        if (this.isAdmin) {
          this.headerDetails.funnelName += ' Admin';
          this.initAdminMenu();
          this.getBuildData();
        } else {
          this.initClientMenu();
        }

        if (this.funnelType === ProgramType.hub) {
          this.header.titleService.setProductOverride('');
        } else {
          this.header.titleService.setProductOverride(null);
        }
        this.header.titleService.setQualifier(this.headerDetails.funnelName);
      })
    );
  }

  setHeaderTitles(): void {
    // strip any funnel names of unneccessary text
    const name = this.headerDetails.funnelName.replace(
      ' Learning Community',
      ''
    );
    const headerTypes = {
      community: () => {
        this.headerDetails.title = `${name} `;
        this.headerDetails.subtitle = 'Learning Community';
      },
      hub: () => {
        this.headerDetails.title = 'FUN HUB<sup>&reg;</sup>';
      },
      default: () => {
        this.headerDetails.title = name;
      },
    };

    headerTypes[this.funnelType]();
  }

  initSharelinkHeader(): void {
    const route = this.ux.routerNavigationService.getDeepestFirstChild();
    if (!route.snapshot.data['useSharelinkHeader']) return;

    this.headerDetails = {
      funnelName: 'Wilson Communities',
      title: 'Wilson',
      subtitle: '',
      iconUrl: '/assets/favicon.svg',
      homeUrl: '/',
      versionNumber: '',
      buildNumber: '',
    };
  }

  initHubLandingHeader(): void {
    const route = this.ux.routerNavigationService.getDeepestFirstChild();
    if (!route.snapshot.data['useHubLandingHeader']) {
      return;
    }

    this.headerDetails = {
      funnelName: 'Wilson Communities',
      title: 'FUN HUB<sup>&reg;</sup>',
      subtitle: '',
      iconUrl: '',
      homeUrl: '#',
      versionNumber: '',
      buildNumber: '',
    };

    this.options = [
      {
        label: 'Administration',
        icon: 'fas fa-lock',
        url: '/admin',
        visible: this.header.userService.user.isAdmin,
        automationId: 'header_menu_administration',
        command: () => mixpanel.track('Go to Admin Site'),
      },
      {
        label: 'Profile',
        icon: 'fas fa-user',
        url: this.determineMyAccountUrl(),
        target: '_blank',
        automationId: 'header_menu_profile',
        command: () => mixpanel.track('Go to User Profile'),
      },
      {
        label: 'Feedback',
        icon: 'fas fa-comment-lines',
        target: '_blank',
        command: () => {
          this.feedback();
          mixpanel.track('Go to Feedback');
        },
      },
      {
        label: 'Contact Wilson',
        icon: 'fas fa-envelope',
        url: 'https://www.wilsonlanguage.com/contact-us/',
        target: '_blank',
        automationId: 'header_menu_contact',
        command: () => mixpanel.track('Go to Contact Us'),
      },
      {
        label: 'Log Out',
        icon: 'fas fa-sign-out',
        styleClass: 'logout',
        command: () => this.logout(),
      },
    ];
  }

  getProgramData(): void {
    this.programTiles = this.header.funnelService
      .getFunnels()
      .map((program) => ({
        ...program,
        description: program.description || ' ',
      }));
  }

  tileAction(event: TileClickEvent): void {
    const tile = event.data;
    event.cancel();

    if (!tile.id && tile.requiredSubscription) {
      window.open(tile.requiredSubscription.signUpLink);
      return;
    }

    const currentProgram = this.currentFunnel.id;

    this.header.userService
      .updateCurrentProgram(tile.id)
      .pipe(
        tap(() => {
          // tile IDs map to their corresponding route in-app
          let path = [tile.id];
          if (this.isAdmin) {
            path = ['admin', ...path];
          }
          this.router.navigate(path);

          // close the dialog
          this.displayModal = false;
          mixpanel.track('Program Changed', {
            'Old Program': currentProgram,
            'New Program': tile.id,
          });
        })
      )
      .subscribe();
  }

  logout(): void {
    this.header.authenticationService.logout();
    mixpanel.track('Logout');
  }

  feedback(): void {
    this.pageUrl = window.location.pathname;
    const feedbackUrl = `https://wilsonlanguagetraining.wufoo.com/forms/wilson-digital-products-feedback?RelatedPage=${this.pageUrl}&ContactId=${this.contactId}`;
    window.open(feedbackUrl, '_blank');
  }

  initAdminMenu(): void {
    this.options = [
      {
        label: 'Customer Portal',
        icon: 'fas fa-user',
        url: '/',
        command: () => mixpanel.track('Go to Customer Portal'),
      },
      {
        label: 'Rebuild Search Cache',
        icon: 'fas fa-sync',
        visible: this.header.userService.user.isTechDev,
        command: () =>
          this.ux.confirmationService.confirm({
            message: `Are you sure you want to rebuild the Algolia search cache?`,
            header: 'Rebuild Search Cache',
            icon: 'pi pi-refresh',
            defaultFocus: 'close',
            acceptButtonStyleClass: 'p-button-danger',
            accept: () => {
              this.adminService.reindexAlgoliaCache().subscribe();
              mixpanel.track('Rebuild Search Cache');
            },
          }),
      },
      {
        label: 'Refresh Sitemap Cache',
        icon: 'fas fa-sync',
        visible: this.header.userService.user.isTechDev,
        command: () =>
          this.ux.confirmationService.confirm({
            message: `Are you sure you want to refresh the sitemap cache?`,
            header: 'Refresh Sitemap Cache',
            icon: 'pi pi-refresh',
            defaultFocus: 'close',
            acceptButtonStyleClass: 'p-button-danger',
            accept: () => {
              this.adminService.refreshSitemapCache().subscribe();
              mixpanel.track('Rebuild Sitemap Cache');
            },
          }),
      },
      {
        label: 'Log Out',
        icon: 'fas fa-sign-out',
        styleClass: 'logout',
        command: () => {
          this.header.redirectService.setRedirectCookie(null, '/admin');
          this.logout();
        },
      },
    ];
  }

  initClientMenu(): void {
    // get values from funnel
    const { revisionsAndSupplementsId, id, discussionBoardUrl } =
      this.currentFunnel;

    this.options = [
      {
        label: 'Change Program',
        icon: 'fas fa-bars',
        styleClass: 'd-md-none',
        command: () => {
          this.showProgramDialog();
          mixpanel.track('Go to Change Programs Menu');
        },
      },
      {
        label: 'Pinned Resources',
        icon: 'fas fa-heart',
        routerLink: [id, 'pinned'],
        styleClass: 'd-block d-md-none',
        command: () => mixpanel.track('Go to Pinned Resources'),
      },
      {
        label: 'Getting Started',
        icon: 'fas fa-play-circle',
        visible: this.currentFunnel.gettingStartedVisible,
        command: () => {
          this.showGettingStartedDialog();
          mixpanel.track('Go to Getting Started Video');
        },
      },
      {
        label: 'Discussion Board',
        icon: 'fas fa-comments-alt',
        url: discussionBoardUrl,
        target: '_blank',
        styleClass: discussionBoardUrl ? 'd-block d-md-none' : '',
        visible: !!discussionBoardUrl,
        command: () => mixpanel.track('Go to Discussion Board'),
      },
      {
        label: 'Announcements',
        icon: 'fas fa-bullhorn',
        routerLink: [id, 'announcements'],
        visible: this.currentFunnel.announcementVisible,
        automationId: 'header_menu_announcements',
        target: '_blank',
        command: () => mixpanel.track('Go to Announcements'),
      },
      {
        label: 'FAQ',
        icon: 'fas fa-question-circle',
        routerLink: [id, 'faq'],
        visible: this.currentFunnel.faqVisible,
        automationId: 'header_menu_faq',
        command: () => mixpanel.track('Go to FAQ'),
      },
      {
        label: 'Revisions & Supplements',
        icon: 'fas fa-pencil',
        routerLink: [id, CollectionType.collections, revisionsAndSupplementsId],
        visible: this.currentFunnel.revisionsAndSupplementsVisible,
        automationId: 'header_menu_revisions_supplements',
        command: () => mixpanel.track('Go to Revisions & Supplements'),
      },
      {
        separator:
          this.currentFunnel.gettingStartedVisible ||
          this.currentFunnel.announcementVisible ||
          this.currentFunnel.faqVisible ||
          this.currentFunnel.revisionsAndSupplementsVisible,
        styleClass: 'd-block d-md-none',
      },
      {
        label: 'Administration',
        icon: 'fas fa-lock',
        url: '/admin',
        visible: this.header.userService.user.isAdmin,
        automationId: 'header_menu_administration',
        command: () => mixpanel.track('Go to Admin Site'),
      },
      {
        label: 'Profile',
        icon: 'fas fa-user',
        url: this.determineMyAccountUrl(),
        target: '_blank',
        automationId: 'header_menu_profile',
        command: () => mixpanel.track('Go to User Profile'),
      },
      {
        label: 'Feedback',
        icon: 'fas fa-comment-lines',
        target: '_blank',
        command: () => {
          this.feedback();
          mixpanel.track('Go to Feedback');
        },
      },
      {
        label: 'Contact Wilson',
        icon: 'fas fa-envelope',
        url: 'https://www.wilsonlanguage.com/contact-us/',
        target: '_blank',
        automationId: 'header_menu_contact',
        command: () => mixpanel.track('Go to Contact Us'),
      },
      {
        label: 'Log Out',
        icon: 'fas fa-sign-out',
        styleClass: 'logout',
        command: () => this.logout(),
      },
    ];
  }

  userMenuKeyboard(event: KeyboardEvent, toggleBtn: HTMLElement): void {
    this.ux.utilityService.menuKeyboardEvent(event, toggleBtn);
  }

  showProgramDialog(): void {
    this.displayModal = true;
  }

  showGettingStartedDialog(): void {
    this.teacherSupportService.updateDisplayGettingStartedDialog(true);
  }

  toggleUserMenu(): void {
    this.menuOpen = !this.menuOpen;
  }

  getBuildData(): void {
    this.headerDetails.buildNumber =
      this.initialLoadService.initialLoad.currentBuildNumber;
    this.headerDetails.versionNumber = 'v' + pkg.version;
  }

  programIconClick() {
    mixpanel.track('Go to Landing Page');
  }

  determineMyAccountUrl(): string {
    return this.initialLoadService.initialLoad.isProduction
      ? 'https://wilsonacademy.com/Account/MyAccount'
      : 'https://dev-academy.wilsonacademy.com/Account/MyAccount';
  }
}
