import { Injectable } from '@angular/core';
import {
  AuthenticationService,
  RedirectService,
  TitleService,
} from '@wilson/wilsonng';
import { FunnelService } from '../funnel.service';
import { UserService } from '../user.service';

/**
 * pass-through service for header injectables
 */
@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(
    public funnelService: FunnelService,
    public authenticationService: AuthenticationService,
    public userService: UserService,
    public titleService: TitleService,
    public redirectService: RedirectService
  ) {}
}
