<nav id="navbar" class="container" *ngIf="funnelId">
  <div
    class="row mb-2"
    [class.mb-md-4]="!isAdmin"
    [class.pt-4]="!isAdmin"
    [class.pt-1]="isAdmin"
  >
    <app-breadcrumbs class="col-12 col-md-8"></app-breadcrumbs>
    <div
      *ngIf="!isAdmin"
      class="col-12 d-none d-md-block col-md-4 text-center text-md-right my-2 my-md-0"
    >
      <ul class="links d-flex justify-content-center justify-content-md-end">
        <li *ngIf="gettingStartedVisible">
          <button class="btn btn-link p-0" (click)="showGettingStartedDialog()">
            Getting Started
          </button>
        </li>
        <li *ngIf="discussionUrl">
          <a
            class="btn btn-link p-0"
            [href]="discussionUrl"
            target="_blank"
            (click)="track('Go to Discussion Board')"
          >
            Discussion Board
          </a>
        </li>
        <li *ngIf="funnelId && showPinnedResourcesLink">
          <a
            class="btn btn-link p-0"
            routerLink="/{{ funnelId }}/pinned"
            (click)="track('Go to Pinned Resources')"
            data-automationId="navbar_pinned_resources"
          >
            Pinned Resources
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
